<div class="row py-3" id="section1">
    <div *ngFor="let card of cardsDetails; let i=index" class="col-xl-3 col-lg-3 col-md-6 col-sm-6 col-12 pe-1">
        <div class="position-relative text-center mb-3">
            <!-- TODO:Implement-- Image need to call from s3 -->
            <img [ngClass]="{'lastImg': i===3?true:false}" alt="Stay" [src]="card.image.location"
                class="img-fluid w-100" />
            <div class="position-absolute py-2">
                <label [ngClass]="{'marginLast label-1': i===3?true:false}" class="rounded-circle ">
                    <div class="priceLabel">
                        <div class="blackjar text-white  font-36 bold">
                            {{card.location}}
                        </div>
                        <div class="font-10 montserratR text-white priceLabelText">
                            {{ "homeDetailCard.from" | translate }}
                            <span class="font-16">&#8377; {{card.minimumPrice * perUnit}} </span>
                            /{{priceFor}}
                        </div>
                    </div>
                </label>
            </div>
        </div>
    </div>
</div>