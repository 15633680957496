<ng-template #modal>
  <div class="p-5">


      <div class="orderSummary">
          <header class="modal-title text-white font-16 montserratSB px-4 py-3 text-center">Account</header>
          <!-- <button type="button" class="close pull-right" aria-label="Close" id="1" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
      </button> -->

          <div class=" text-center ">


              <form [formGroup]="registerForm">
                  <p class="font-14  montserratSB pt-3"> To place your booking now, <br>
                      log into your existing account or sign up. </p>
                  <p class="pt-3 mb-0 montserratSB font-12">Sign up or <span (click)="onClickLogin()"
                          class="pointer font-12 red-text">log in to your account</span> </p>
                  <div class="row medium font-12 pt-4">
                      <div class="col-12">
                          <div class="contentBelowSubHeading p-3">
                              <div class="row-12">

                                  <div class=" mb-3 px-2">
                                      <input pInputText formControlName="mobileNumber" type="text"
                                          placeholder="Phone Number"
                                          class="w-100 light px-3 py-4 inputBoxAddUser font-12">
                                      <div class="error red-text " *ngIf="
                                              registerForm.get('mobileNumber')?.invalid &&
                                              registerForm.get('mobileNumber')?.dirty">
                                          <span *ngIf="registerForm.get('mobileNumber')?.hasError('required')">Mobile
                                              Number is required!
                                          </span>
                                          <span *ngIf="
                                              !registerForm.get('mobileNumber')?.hasError('pattern') &&
                                              registerForm.get('mobileNumber')?.hasError('minlength')">Mobile Number
                                              should have minimum 3 numbers !
                                          </span>
                                          <span *ngIf="
                                              !registerForm.get('mobileNumber')?.hasError('pattern') &&
                                              registerForm.get('mobileNumber')?.hasError('maxlength')">Mobile Number
                                              should have maximum 18 numbers !
                                          </span>
                                          <span *ngIf="registerForm.get('mobileNumber')?.hasError('pattern')">Mobile
                                              Number is invalid!
                                          </span>
                                      </div>
                                  </div>
                              </div>
                              <div class="row-12">

                                  <div class=" mb-3 px-2">
                                      <input pInputText formControlName="firstName" type="text" placeholder="Name"
                                          class="w-100 light  px-3 py-4 inputBoxAddUser font-12">
                                      <div class="error red-text "
                                          *ngIf="registerForm.controls['firstName'].invalid &&registerForm.controls['firstName'].dirty">
                                          <span *ngIf="registerForm.controls['firstName'].errors?.['required']">Please
                                              enter
                                              name.<br></span>
                                          <span
                                              *ngIf="!registerForm.controls['firstName'].errors?.['required'] && registerForm.controls['firstName'].errors?.['minlength']">Name
                                              should have atleast 3
                                              characters long.<br></span>
                                          <span
                                              *ngIf="!registerForm.controls['firstName'].errors?.['required'] && registerForm.controls['firstName'].errors?.['maxlength']">Name
                                              is too long.<br></span>
                                      </div>
                                  </div>
                              </div>
                              <div class="row-12">

                                  <div class=" mb-3 px-2">
                                      <input pInputText formControlName="emailId" type="email" placeholder="Email"
                                          class="w-100 light  px-3 py-4 inputBoxAddUser font-12">
                                      <div class="error red-text" *ngIf="
                                              registerForm.get('emailId')?.invalid &&
                                              registerForm.get('emailId')?.dirty">
                                          <span *ngIf="
                                              registerForm.get('emailId')?.hasError('required')">Email Address is
                                              Required
                                          </span>
                                          <span *ngIf="
                                              !registerForm.get('emailId')?.hasError('required') &&
                                              registerForm.get('emailId')?.hasError('email')">Email Address is not
                                              valid
                                          </span>
                                      </div>
                                  </div>
                              </div>


                              <div class="row-12">

                                  <div class=" mb-3 px-2">
                                      <input pInputText formControlName="password" type="password"
                                          placeholder="Password"
                                          class="w-100 light  px-3 py-4 inputBoxAddUser font-12">
                                      <div class="error red-text "
                                          *ngIf="registerForm.controls['password'].invalid &&registerForm.controls['password'].dirty">
                                          <span *ngIf="registerForm.controls['password'].errors?.['required']">Please
                                              enter the password.<br></span>
                                          <span
                                              *ngIf="!registerForm.controls['password'].errors?.['required'] && registerForm.controls['password'].errors?.['minlength']">Password
                                              needs to be atleast 5
                                              characters long.<br></span>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>

                  <div class="row-12">
                      <div class="text-center pt-2 px-4">

                          <button type="button" (click)="onClickSubmit()"
                              class="font-18 w-100 text-white menuRedBG montserratSB py-3 border-0 submitBtn  pointer">Sign
                              Up
                          </button>
                          <p class="font-12 montserratSB pt-2">By creating an account, I accept the <span
                                  class="pointer"><u> Terms & Conditions</u></span></p>
                      </div>
                  </div>
              </form>
          </div>
      </div>
  </div>
</ng-template>

<!-- <app-login-modal [onOpenModal]="openLogin"></app-login-modal> -->
<!-- <app-verify-otp-modal [onOpenModal]="openVerifyOtp"></app-verify-otp-modal> -->
