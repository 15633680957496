import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LoginComponent } from './login/login.component';
import { NoContentComponent } from './nocontent/nocontent.component';
import { BookEventsComponent } from './book-events/book-events.component';
import { FoodOrderComponent } from './food-order/food-order/food-order.component';
import { SuccessComponent } from './success/success.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsAndConditionComponent } from './terms-and-condition/terms-and-condition.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', redirectTo: 'home' },
  { path: 'login', component: LoginComponent },
  { path: 'book-event', component: BookEventsComponent },
  { path: 'food-order', component: FoodOrderComponent },
  { path: 'success/:bookingId/:orderId', component: SuccessComponent },
  {
    path: 'book-food', loadChildren: () => import('./booking-food/booking-food.module')
      .then((m) => m.BookingFoodModule)
  },
  {
    path: 'book-services', loadChildren: () => import('./book-services/book-services.module')
      .then((m) => m.BookingServicesModule)
  },
  {
    path: 'booking-stay', loadChildren: () => import('./booking-stay/booking-stay.module')
      .then((m) => m.BookingStayModule)
  },
  {
    path: 'book-activity', loadChildren: () => import('./book-activity/book-activity.module')
      .then((m) => m.BookActivityModule)
  },
  {
    path: 'my-booking', loadChildren: () => import('./my-booking/my-booking.module')
      .then((m) => m.MyBookingModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module')
      .then((m) => m.RegisterModule)
  },
  {
    path: 'transportation',
    loadChildren: () => import('./transportation/transportation.module').then(m => m.TransportationModule)
  },
  {
    path: 'bookActivity',
    loadChildren: () => import('./book-activity/book-activity.module').then(m => m.BookActivityModule)
  },
  {
    path: 'myAccount',
    loadChildren: () => import('./my-account/my-account.module').then(m => m.MyAccountModule)
  },

  { path: 'home', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) },
  { path: 'listing', loadChildren: () => import('./listing/listing.module').then(m => m.ListingModule) },
  {
    path: 'property-description',
    loadChildren: () => import('./propertydescription/propertydescription.module')
      .then(m => m.PropertydescriptionModule)
  },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: 'terms-and-condition', component: TermsAndConditionComponent },
  { path: '**', component: NoContentComponent },

];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
