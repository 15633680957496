<div class="background">
  <div class="container">
    <div class="row ">
      <div class="col-12 col-md-6">
        <div>
          <img alt="success" src="../../assets/img/success-icon.png" class="success-icon" />
        </div>
        <h1 class=" py-3 montserratL">
          {{paymentStatus === 'Completed' || paymentStatus === 'Successfull' ? 'Congratulations!' : paymentStatus}}

        </h1>
        <h2 class="montserratL pb-2"> {{selectedTitle.mainTitle}} </h2>
        <p class="montserratL py-3"> {{selectedTitle.subTitle}}</p>
      </div>
      <div class="col-12 col-md-6">
        <div>
          <img alt="success" [src]="selectedTitle.imagePath" class="w-100 my-4" />
        </div>
      </div>
    </div>

    <div id="becomeAHost" class="py-5">
      <div class="hostBG p-5">
        <div class="row">
          <div class="col-xl-8 col-lg-8 col-md-7 col-sm-6 col-12  text-white">
            <label class="blackjar">
              Become a Host
            </label>
            <h3 class="montserratSB pt-4 mb-2">Why host on BookmyLoft?</h3>
            <p class="montserratR font-12 mb-0">No matter what kind of home or room you have
              to
              share. BookmyLoft makes it simple
              and secure to host travellers. you are in full control of your availability,
              prices, house rules and how you interact with guests. Open your world to
              others
              and recommended a place you like. We will pay 50% for you on our earnings
              for
              the booking.</p>
          </div>
          <div class="col-xl-4 col-lg-4 col-md-5 col-sm-6 col-12">
            <div class="text-white text-end montserratSB font-12 textDownload py-3">
              Download the Bookmyloft<br />
              App to become a host
            </div>
            <div class="row">
              <div class="col-xl-8 col-lg-7 col-md-7 col-sm-6 col-6 text-end playstore pe-1">
                <div (click)="onNavigateToAppDownload('ios')">
                  <img alt="App store" src="../../assets/img/app-store.png" class="img-fluid w-100 my-1" />
                </div>
                <div  (click)="onNavigateToAppDownload('playstore')">
                  <img alt="Play store" src="../../assets/img/play-store.png" class="img-fluid w-100 my-1" />
                </div>
              </div>
              <div class="col-xl-4 col-lg-5 col-md-5 col-sm-6 col-6 text-end ps-1">
                <img alt="barcode" src="../../assets/img/BML-qr-code.png" class="img-fluid w-100 barcode" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>
