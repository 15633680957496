import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FoodOrderComponent } from './food-order/food-order.component';
import { OrderSummaryComponent } from './order-summary/order-summary.component';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';



@NgModule({
  declarations: [FoodOrderComponent, OrderSummaryComponent],
  imports: [
    CommonModule,
    TabsModule.forRoot(),
    BsDatepickerModule.forRoot(),
    MatFormFieldModule
  ]
})
export class FoodOrderModule { }
