import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({ name: 'customCapitalize' })
export class CustomCapitalizePipe implements PipeTransform {
    transform(value: string): string {
        const str = value.split("_");
        let concatenatedString = '';
        str.forEach((val, index) => {
            concatenatedString = concatenatedString +
                (index === 0 ? (val[0].toUpperCase() + val.substr(1).toLowerCase()) : (' ' + val.toLowerCase()));
        });
        return concatenatedString;
    }
}
