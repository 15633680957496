
import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { RestService } from '../core/rest.service';
import { IBooking, IRestaurant } from '../interfaces/book-food.interface';
import { IPaginationResponse } from '../interfaces/rest-page-response.interface';
import { IRestResponse } from '../interfaces/rest-response.interface';
import { IService, IServiceById  } from '../interfaces/service.interface';
import { ISubSpot } from '../interfaces/sub-spot.interface';
import { Environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BookFoodService {
  public foodCartData = new Subject<any>();
  public foodCartPagination = new Subject<{ pageSize: number, totalElements: number }>();
  constructor(private restService: RestService) { }

  public getFoodCartData(foodCartData:any): void {
    this.foodCartData.next(foodCartData);
  }
  public getFoodMenuItems(data:any): Observable<IRestResponse<IPaginationResponse<Array<IRestaurant>>>> {
    return this.restService
      .post(Environment.UrlPath + `/foodAndBeverage/menuItems`, data).pipe(
        map((res: any) => res)
        , catchError((error) =>
          throwError(error))
      );
  }
  public getServiceDetailsById(menuItemId: string): Observable<IRestResponse<any>> {
    return this.restService
      .get(Environment.UrlPath + `/foodAndBeverage/${menuItemId}`, null).pipe(
        map((res: any) => res)
        , catchError((error) =>
          throwError(error))
      );
  }
  public addBookingFoodItem(data:any): Observable<IRestResponse<IBooking>> {
    return this.restService
      .post(Environment.UrlPath + `/booking`, data).pipe(
        map((res: any) => res)
        , catchError((error) =>
          throwError(error))
      );
  }
  public updateBookingFoodItem(data:any, bookingDetailId: string): Observable<IRestResponse<IBooking>> {
    return this.restService
      .put(Environment.UrlPath + `/booking/${bookingDetailId}`, data).pipe(
        map((res: any) => res)
        , catchError((error) =>
          throwError(error))
      );
  }
  public getMostPopularBulkFood(data:any): Observable<IRestResponse<IPaginationResponse<ISubSpot[]>>> {
    return this.restService.post(Environment.UrlPath +
      `/foodAndBeverage/search`, data, false)
      .pipe(
        map((res:any) => res),
        catchError(error => throwError(error))
      );
  }
}
