<div class="orderSummary">
  <header class="text-white font-16 montserratSB px-4 py-3 text-center">Summary</header>
  <section class="px-4 py-3">
    <div class="montserratSB font-16 text-black">
      <div class="row">
        <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-8">Food Delivery Address</div>
        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-4 text-end font-12 montserratB"><u>Edit</u></div>
      </div>
    </div>
    <p class="montserratR font-12 text-black pt-1 m-0">
      <img alt="Map" src="../../../assets/img/map-pin.png" /> 22, MIDC Central Rd, Chembur, Mumbai
    </p>
  </section>
  <section class="px-4 py-3">
    <div class="montserratSB font-16 text-black">
      <div class="row">
        <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-8">Select Delivery Date</div>
        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-4 text-end font-12 montserratB"><u>Edit</u></div>
      </div>
    </div>
    <p class="montserratR font-12 text-black pt-1 m-0">
      <img alt="Map" src="../../../assets/img/calendar.png" /> Monday 15th September 2020
    </p>
  </section>
  <section class="px-4 py-3">
    <div class="montserratSB font-16 text-black">
      <div class="row pb-2">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">Select Delivery Time</div>
      </div>
    </div>
    <div class="row py-1">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
        <label class="font-16 montserratSB green-text">Breakfast</label>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6"></div>
    </div>
    <div class="row py-1">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
        <label class="font-16 montserratSB orange-text">Lunch</label>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6"></div>
    </div>
    <div class="row py-1">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
        <label class="font-16 montserratSB violet-text">Snacks</label>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6"></div>
    </div>
    <div class="row py-1">
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
        <label class="font-16 montserratSB red-text">Dinner</label>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6"></div>
    </div>
  </section>
  <section class="px-4 py-3">
    <div class="montserratSB font-16 text-black">
      <div class="row pb-2">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">Breakfast <span class="montserratL">- 2
            Items</span></div>
      </div>
    </div>
    <div class="row py-1 summaryRupee">
      <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5">
        <div class="font-14 montserratR"> <label class="mb-1">Idli (2 Pcs)</label>
          <div class="text-grey font-12"><img alt="rupee" src="../../../assets/img/rupee.png" class="me-1" />
            <span>50</span>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 px-1"></div>
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 ps-1 text-end">
        <div class="text-black font-14 montserratR"><img alt="rupee" src="../../../assets/img/rupee.png" class="me-1" />
          <span>50</span>
        </div>
      </div>
    </div>
    <div class="row py-1 summaryRupee">
      <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5">
        <div class="font-14 montserratR"> <label class="mb-1">Idli (2 Pcs)</label>
          <div class="text-grey font-12"><img alt="rupee" src="../../../assets/img/rupee.png" class="me-1" />
            <span>50</span>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 px-1"></div>
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 ps-1 text-end">
        <div class="text-black font-14 montserratR"><img alt="rupee" src="../../../assets/img/rupee.png" class="me-1" />
          <span>50</span>
        </div>
      </div>
    </div>
  </section>
  <section class="px-4 py-3">
    <div class="montserratSB font-16 text-black">
      <div class="row pb-2">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">Lunch <span class="montserratL">- 1
            Item</span></div>
      </div>
    </div>

    <div class="row py-1 summaryRupee">
      <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5">
        <div class="font-14 montserratR"> <label class="mb-1">Idli (2 Pcs)</label>
          <div class="text-grey font-12"><img alt="rupee" src="../../../assets/img/rupee.png" class="me-1" />
            <span>300</span>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 px-1"></div>
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 ps-1 text-end">
        <div class="text-black font-14 montserratR"><img alt="rupee" src="../../../assets/img/rupee.png" class="me-1" />
          <span>300</span>
        </div>
      </div>
    </div>
  </section>

  <section class="px-4 py-3">
    <div class="montserratSB font-16 text-black">
      <div class="row pb-2">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">Snacks <span class="montserratL">- 1
            Item</span></div>
      </div>
    </div>

    <div class="row py-1 summaryRupee">
      <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5">
        <div class="font-14 montserratR"> <label class="mb-1">Idli (2 Pcs)</label>
          <div class="text-grey font-12"><img alt="rupee" src="../../../assets/img/rupee.png" class="me-1" />
            <span>300</span>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 px-1"></div>
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 ps-1 text-end">
        <div class="text-black font-14 montserratR"><img alt="rupee" src="../../../assets/img/rupee.png" class="me-1" />
          <span>300</span>
        </div>
      </div>
    </div>
  </section>
  <section class="px-4 py-3">
    <div class="montserratSB font-16 text-black">
      <div class="row pb-2">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">Dinner <span class="montserratL">- 1
            Item</span></div>
      </div>
    </div>

    <div class="row py-1 summaryRupee">
      <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5">
        <div class="font-14 montserratR"> <label class="mb-1">Idli (2 Pcs)</label>
          <div class="text-grey font-12"><img alt="rupee" src="../../../assets/img/rupee.png" class="me-1" />
            <span>300</span>
          </div>
        </div>
      </div>
      <div class="col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 px-1"></div>
      <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3 ps-1 text-end">
        <div class="text-black font-14 montserratR"><img alt="rupee" src="../../../assets/img/rupee.png" class="me-1" />
          <span>300</span>
        </div>
      </div>
    </div>
  </section>
  <section class="px-4 py-3">
    <div class="row py-1 summaryRupee">
      <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-7 pe-1">
        <div class="font-14 montserratR"> <label class="mb-1">Subtotal</label> </div>
      </div>
      <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5 ps-1  text-end">
        <div class="text-black font-14 montserratSB"><img alt="rupee" src="../../../assets/img/rupee.png"
            class="me-1" />
          <span>300</span>
        </div>
      </div>
    </div>
    <div class="row py-1 summaryRupee">
      <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-7 pe-1">
        <div class="font-14 montserratR"> <label class="mb-1">Delivery Charge</label> </div>
      </div>
      <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5 ps-1  text-end">
        <div class="text-black font-14 montserratSB"><img alt="rupee" src="../../../assets/img/rupee.png"
            class="me-1" />
          <span>300</span>
        </div>
      </div>
    </div>
    <div class="row py-1 summaryRupee">
      <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-7 pe-1">
        <div class="font-14 montserratR"> <label class="mb-1 me-2">Coupon</label> <label
            class="mb-1 font-9 couponLabel px-2 blue-text">ADVANCEN</label> </div>
      </div>
      <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5 ps-1  text-end">
        <div class="text-black font-14 montserratSB">- <img alt="rupee" src="../../../assets/img/rupee.png"
            class="me-1" />
          <span>300</span>
        </div>
      </div>
    </div>
    <div class="row py-1 summaryRupee">
      <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-7 pe-1">
        <div class="font-14 montserratR"> <label class="mb-1">Taxes and Charge</label> </div>
      </div>
      <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5 ps-1  text-end">
        <div class="text-black font-14 montserratSB"><img alt="rupee" src="../../../assets/img/rupee.png"
            class="me-1" />
          <span>300</span>
        </div>
      </div>
    </div>
  </section>

  <section class="px-4 py-3">
    <div class="row py-1 summaryRupee">
      <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-7 pe-1">
        <div class="font-16 montserratR red-text"> <label class="mb-1">To Pay</label> </div>
      </div>
      <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5 ps-1  text-end">
        <div class="text-black font-16 montserratSB red-text"><img alt="rupee" src="../../../assets/img/red-rupee.png"
            class="me-1" />
          <span>800</span>
        </div>
      </div>
    </div>
  </section>

  <section class="px-4 py-3 border-0">
    <div class="row py-1 summaryRupee">
      <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
        <div class="font-14 montserratR"> <label class="mb-1 text-grey">Not logged in?</label> </div>
        <div class="blue-text montserratR font-16"><u>Log In now</u> <span class="font-11 px-2">or</span> <u>Sign up</u>
        </div>
        <div>
          <button (click)="openModal(template)"
            class="text-white menuBlueBG border-0 w-100 px-2 py-2 montserratR font-16 mt-3">Proceed to Checkout</button>
        </div>
        <div class="montserratL font-12 pt-1 pb-1">All taxes & levies are included in the price.</div>
      </div>
    </div>
  </section>


</div>

<ng-template #template>
  <!-- <div class="modal-header">
    <h4 class="modal-title pull-left">Modal</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div> -->
  <div class="modal-body">
    <div class="orderSummary">
      <header class="text-white font-16 montserratSB px-4 py-3 text-center">Summary</header>
      <section class="px-4 py-3">
        <div class="montserratSB font-16 text-black">
          <div class="row">
            <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-8">Food Delivery Address</div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-4 text-end font-12 montserratB"><u>Edit</u></div>
          </div>
        </div>
        <p class="montserratR font-12 text-black pt-1 m-0">
          <img alt="Map" src="../../../assets/img/map-pin.png" /> 22, MIDC Central Rd, Chembur, Mumbai
        </p>
      </section>
      <section class="px-4 py-3">
        <div class="montserratSB font-16 text-black">
          <div class="row">
            <div class="col-xl-9 col-lg-9 col-md-9 col-sm-9 col-8">Select Delivery Date</div>
            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-4 text-end font-12 montserratB"><u>Edit</u></div>
          </div>
        </div>
        <p class="montserratR font-12 text-black pt-1 m-0">
          <img alt="Map" src="../../../assets/img/calendar.png" /> Monday 15th September 2020
        </p>
      </section>
      <section class="px-4 py-3">
        <div class="montserratSB font-16 text-black">
          <div class="row pb-2">
            <div class="col-xl-7 col-lg-7 col-md-8 col-sm-9 col-8 my-1">Select Delivery Time</div>
            <div class="col-xl-5 col-lg-5 col-md-4 col-sm-3 col-4 text-end font-12 montserratL">
              <div class="position-relative">
                <img alt="date pickker" src="../../../assets/img/clock.png" class="dateSelector position-absolute" />
                <input type="text" placeholder="Datepicker" class="form-control font-14" bsDatepicker></div>
            </div>
          </div>
        </div>
      </section>
      <section class="px-4 py-3">
        <div class="montserratSB font-16 text-black">
          <div class="row pb-2">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">Number of Guests</div>
            <div class="col-xl-7 col-lg-7 col-md-6 col-sm-6 col-6 text-end font-12 montserratR">
              <div class="btn-group w-100 pt-2" dropdown>
                <button id="button-config" dropdownToggle type="button"
                  class="dropdown-toggle custom-dropdown white-bg w-100" aria-controls="dropdown-config"> For 10 - 15
                  people <span class="caret"></span>
                </button>
                <ul id="dropdown-config" *dropdownMenu class="dropdown-menu" role="menu"
                  aria-labelledby="button-config">
                  <li role="menuitem"><a class="dropdown-item" href="#">Dropdown 1</a></li>
                  <li role="menuitem"><a class="dropdown-item" href="#">Dropdown 2</a></li>
                  <li role="menuitem"><a class="dropdown-item" href="#">Dropdown 3</a></li>
                </ul>
              </div>
            </div>
            <div class="col-xl-5 col-lg-5 col-md-6 col-sm-6 col-6 text-end font-14 montserratR">
              <div class="py-2"><img alt="rupee" src="../../../assets/img/rupee.png" class="me-1" />
                <span>9,999</span>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section class="px-4 py-3">
        <div class="montserratSB font-16 text-black">
          <div class="row pb-2">
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">Any Special Instructions</div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-end text-grey font-10 montserratR labelMax50Char">max 50
              words</div>
            <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 font-12 montserratR">
              <mat-form-field class="example-full-width w-100">
                <textarea matInput placeholder="Your message (optional)" class="w-100 custom-textarea p-3"></textarea>
              </mat-form-field>
            </div>
          </div>
        </div>
      </section>

     
      <section class="px-4 py-3">
        <div class="row py-1 summaryRupee">
          <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-7 pe-1">
            <div class="font-14 montserratR"> <label class="mb-1">Subtotal</label> </div>
          </div>
          <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5 ps-1  text-end">
            <div class="text-black font-14 montserratSB"><img alt="rupee" src="../../../assets/img/rupee.png"
                class="me-1" />
              <span>300</span>
            </div>
          </div>
        </div>
        <div class="row py-1 summaryRupee">
          <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-7 pe-1">
            <div class="font-14 montserratR"> <label class="mb-1">Delivery Charge</label> </div>
          </div>
          <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5 ps-1  text-end">
            <div class="text-black font-14 montserratSB"><img alt="rupee" src="../../../assets/img/rupee.png"
                class="me-1" />
              <span>300</span>
            </div>
          </div>
        </div>
        <div class="row py-1 summaryRupee">
          <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-7 pe-1">
            <div class="font-14 montserratR"> <label class="mb-1 me-2">Coupon</label> <label
                class="mb-1 font-9 couponLabel px-2 blue-text">ADVANCEN</label> </div>
          </div>
          <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5 ps-1  text-end">
            <div class="text-black font-14 montserratSB">- <img alt="rupee" src="../../../assets/img/rupee.png"
                class="me-1" />
              <span>300</span>
            </div>
          </div>
        </div>
        <div class="row py-1 summaryRupee">
          <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-7 pe-1">
            <div class="font-14 montserratR"> <label class="mb-1">Taxes and Charge</label> </div>
          </div>
          <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5 ps-1  text-end">
            <div class="text-black font-14 montserratSB"><img alt="rupee" src="../../../assets/img/rupee.png"
                class="me-1" />
              <span>300</span>
            </div>
          </div>
        </div>
      </section>

      <section class="px-4 py-3">
        <div class="row py-1 summaryRupee">
          <div class="col-xl-7 col-lg-7 col-md-7 col-sm-7 col-7 pe-1">
            <div class="font-16 montserratR red-text"> <label class="mb-1">To Pay</label> </div>
          </div>
          <div class="col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5 ps-1  text-end">
            <div class="text-black font-16 montserratSB red-text"><img alt="rupee"
                src="../../../assets/img/red-rupee.png" class="me-1" />
              <span>800</span>
            </div>
          </div>
        </div>
      </section>

      <section class="px-4 py-3 border-0">
        <div class="row py-1 summaryRupee">
          <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 text-center">
            <div class="font-14 montserratR"> <label class="mb-1 text-grey">Not logged in?</label> </div>
            <div class="blue-text montserratR font-16"><u>Log In now</u> <span class="font-11 px-2">or</span> <u>Sign
                up</u>
            </div>
            <div>
              <button disabled
                class="text-white menuBlueBG border-0 w-100 px-2 py-2 montserratR font-16 mt-3">Proceed to
                Checkout</button>
            </div>
            <div class="montserratL font-12 pt-1 pb-1">All taxes & levies are included in the price.</div>
          </div>
        </div>
      </section>


    </div>
  </div>
</ng-template>
