import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { IRestResponse } from 'src/app/interfaces/rest-response.interface';
import { UserService } from 'src/app/services';
import { validateAllFormFields } from 'src/app/utils/validateform';

@Component({
  selector: 'app-verify-otp-modal',
  templateUrl: './verify-otp-modal.component.html',
  styleUrls: ['./verify-otp-modal.component.scss']
})
export class VerifyOtpModalComponent implements OnInit, OnDestroy {

  @ViewChild('modal') modal: any;
  @Input() onOpenModal!: Subject<any>;
  modalRef!: BsModalRef;
  eventsSubscription: any;
  verifyForm!: FormGroup;
  showVerification = false;
  openRegister: Subject<void> = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.initVerifyForm();

    this.eventsSubscription = this.onOpenModal.subscribe(res => {
      this.modalRef = this.modalService.show(this.modal);
    });
  }

  initVerifyForm() {
    this.verifyForm = this.formBuilder.group({
      mobileNumber: this.formBuilder.control('', Validators.required),
      // verificationCode: this.formBuilder.control({ value: '', disabled: true })
    });
  }

  onClickGenerateOtp() {
    if (!this.verifyForm.valid) {
      validateAllFormFields(this.verifyForm);
      return;
    }

    this.userService.generateOtp(this.verifyForm.get('mobileNumber')?.value).subscribe((res: IRestResponse<any>) => {
      if (res.status.code === 200) {
        this.showVerification = true;
        this.verifyForm.addControl('verificationCode', this.formBuilder.control('', Validators.required))
      }
    });
  }


  onClickVerify() {

    if (!this.verifyForm.valid) {
      validateAllFormFields(this.verifyForm);
      return;
    }

    this.userService.userVerifyOtp(this.verifyForm.value).subscribe((res: IRestResponse<any>) => {
      if (res.status.code === 200) {
        this.modalRef.hide();
        this.openRegister.next(this.verifyForm.get('mobileNumber')?.value);
      }
    });
  }

  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }
}
