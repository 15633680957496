<div class="Background">
    <div class="container">
        <div class="row" *ngIf="!createEvent">
            <form [formGroup]="eventTypeForm">
                <div class="row">
                    <h1>Event</h1>
                </div>
                    <div class="row">
                        <select formControlName="types" class="form-control font-12 w-100 light height-40 px-3">
                          <option value="" selected>Select your type of Event</option>
                          <option *ngFor="let type of eventType" value="{{type.type}}">{{type.type}}</option>
                        </select>
                </div>
                <div class="row">
                    <div *ngIf="eventTypeForm.controls['types'].invalid && eventTypeForm.controls['types'].dirty" >
                        <span class="error" *ngIf="eventTypeForm.controls['types'].errors?.['required']">Type of Event is required</span>
                        </div>
                </div>

                <div class="row">
                    <button type="submit" class="btn btn-primary"(click)="onTypeSubmit()">Create an Event</button>
                </div>
            </form>
        </div>
        <div class="row" *ngIf="createEvent">
            <div class="row">
                <form [formGroup]="createEventForm" (ngSubmit)="onEventSubmit()">
                    <div class="row">
                        <h1>Create Event Invite </h1>
                    </div>
                    <br>
                    <div class="row">
                        <h1>{{eventTypeForm.value.types}}</h1>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col">
                            <h1>Event Name</h1>
                        </div>
                        <div class="col">
                            <input pInputText class="form-control w-100 bg-white my-2 light font-12" formControlName="eventName" type="text" placeholder="Enter Event Name">
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col">
                            <h1>Occasion</h1>
                        </div>
                        <div class="col">
                                <select formControlName="occasion" class="form-control font-12 w-100 light height-40 px-3">
                                  <option value="" selected>Select your Occasion</option>
                                  <option *ngFor="let type of occasion" value="{{type.name}}">{{type.name}}</option>
                                </select>

                        </div>
                        </div>
                        <br>
                    <div class="row">
                        <div class="col">
                            <h1>Where's the Event Venue</h1>
                        </div>
                        <div class="col">
                            <input pInputText formControlName="venue" type="text"
                            class="form-control w-100 bg-white light font-12" name="" placeholder="Enter you Venue">
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col-4">
                            <div class="row">
                                <h1>Date</h1>
                            </div>
                            <div class="row">
                                    <div>
                                        <span>From date</span>
                                        <input type="text" formControlName="date" bsDatepicker
                                           class="form-control" [bsConfig]="{ dateInputFormat: 'DD/MM/YYYY'}">
                                    </div>
                            </div>
                        </div>
                        <div class="col-4">
                            <div class="row-6">
                                <h1>
                                    From
                                </h1>
                            </div>
                                <div class="row-6">
                                        <div>
                                            <timepicker formControlName="startTime"></timepicker>
                                        </div>
                                </div>

                        </div>
                        <div class="col-4">
                            <div class="row">
                                <h1>
                                    To
                                </h1>
                            </div>
                                <div class="row">
                                        <div>
                                            <timepicker  formControlName="endTime"></timepicker>
                                        </div>
                                </div>
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col">
                            <h1>Dress Code</h1>
                        </div>
                        <div class="col">
                            <input pInputText formControlName="dressCode" type="text"
                            class="form-control w-100 bg-white light font-12" name="" placeholder="Enter Dress Code">
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col">
                            <h1> Food </h1>
                        </div>
                        <div class="col">
                            <input pInputText formControlName="food" type="text"
                            class="form-control w-100 bg-white light font-12" name="" placeholder="Enter Food">
                        </div>
                    </div>
                    <br>
                    <div class="row">
                        <div class="col">
                            <h1> Transport </h1>
                        </div>
                        <div class="col">
                            <input pInputText formControlName="transport" type="text"
                            class="form-control w-100 bg-white light font-12" name="" placeholder="Enter transport">
                        </div>
                    </div>
                    <button class="btn btn-success" type="submit">SEND INVITATION NOW</button>
                </form>
            </div>
        </div>
    </div>
</div>