<div>
  <div class="position-relative">
    <button class="border-0 outline-0 bg-transparent position-absolute closeicon"  (click)="onCloseCalender()">
      <img alt="Close Button" src="../../../assets/img/close.png" class="" />
    </button>
  </div>
  <div class="calendar-wrapper">
    <div class="d-flex align-items-center">
      <button class="button is-text" (click)="changeNavMonth(-1)" [disabled]="!canChangeNavMonth(-1)">
        <img alt="previous month" src="../../../assets/img/arrow-prev.png" class="next-prev-icon" />
      </button>
    </div>
    <div class="calendar" *ngFor="let month of gridArr">

      <div class="calendar-nav">

        <div class="calendar-nav-previous-month">
          <!-- <button class="button is-text" (click)="changeNavMonth(-1)" [disabled]="!canChangeNavMonth(-1)">
            <span>
              < </span> </button> -->
        </div>
        <div *ngIf="month.date">{{month.date.format('MMMM YYYY')|titlecase}}</div>
        <div class="calendar-nav-next-month">
          <!-- <button class="button is-text" (click)="changeNavMonth(1)" [disabled]="!canChangeNavMonth(1)">
            <span> > </span>
          </button> -->
        </div>
      </div>


      <div class="calendar-container">
        <div class="calendar-header">
          <div class="calendar-date" *ngFor="let day of weekDaysHeaderArr">
            <button class="date-item">
              {{day|titlecase|slice:0:3 }}
            </button>
          </div>
        </div>
        <div class="calendar-body">
          <div *ngFor="let day of month.days" class="calendar-date" [class.is-disabled]="!day.available"
            [class.is-active]="day.isRangeStart || day.isRangeEnd || day.isSingle"
            [class.calendar-range-start]="day.isRangeStart && range[day.rangeIndex][1]"
            [class.calendar-range-end]="day.isRangeEnd" [class.calendar-range]="day.isRange">



            <div class="d-flex text-center custom-date">
              <button type="button" *ngIf="day.value !== 0" class="date-item"
                (click)="selectDay(day)">{{day.value}}</button>
              <button *ngIf="day.value === 0" class="date-item"></button>
              <div class="date-price"> {{day.price === 'N.A.' ? day.price : (day.price ? ('&#8377;' +
                convertPriceToString(day.price)): '')}}
              </div>
            </div>

          </div>
        </div>



        <!-- <div class="calendar-footer">
          <button class="calendar-footer-today button is-small is-text" (click)="selectToday()">{{
            'datePicker.today' | translate }}</button>
          <button class="calendar-footer-clear button is-small is-text" (click)="clearDays()">{{
            'datePicker.clear' | translate }}</button>
        </div> -->
      </div>


    </div>
    <div class="d-flex align-items-center">
      <button class="button is-text" (click)="changeNavMonth(1)" [disabled]="!canChangeNavMonth(1)">
        <img alt="Next month" src="../../../assets/img/arrow-next.png" class="next-prev-icon" />
      </button>
    </div>
  </div>
  <div class="options">
    <app-time-picker [timeInput]="dateInput" [conf]="conf" (timeDataEvent)="onGetTimeData($event)"></app-time-picker>
  </div>


  <!-- <div class="options">
        <label>
            <span *ngIf="petitionType == 'range'">Range</span>
            <span *ngIf="petitionType == 'single'">Single</span>
            <input type="checkbox" [checked]="isChecked()" (input)="petitionTypeToggle()" />
        </label>
        <button type="button" (click)="addNewRange()" *ngIf="range[range.length-1].length == 2">Add range</button>
    </div> -->
</div>
