import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({ name: 'dateAndTime' })
export class DateAndTimePipe implements PipeTransform {
  transform(date: Date | string): any {
    if (date) {
      const parsedDate = new Date(date);
      const isValidDate = !isNaN(parsedDate.getTime());

      if (isValidDate) {
        return new DatePipe('en-US').transform(parsedDate, 'd/M/yyyy, h:mm a');
      }
    }
    return date;
  }
}

