import { Component, OnInit } from '@angular/core';
import { EventType, Occasion } from '../interfaces/book-events.interface';
import { FormBuilder, FormGroup, FormControl, Validators } from '@angular/forms';
import { validateAllFormFields } from '../utils/validateform';

@Component({
  selector: 'app-book-events',
  templateUrl: './book-events.component.html',
  styleUrls: ['./book-events.component.scss']
})
export class BookEventsComponent implements OnInit {
  eventType!: EventType[];
  eventTypeForm!: FormGroup;
  createEventForm!: FormGroup;
  createEvent = false;
  occasion!: Occasion[];
  constructor(private formBuilder: FormBuilder) { }

  ngOnInit(): void {
    this.initEventTypeForm();
    this.getEventDetails();
  }
  initEventTypeForm() {
    this.eventTypeForm = this.formBuilder.group({
      types: new FormControl('', [
        Validators.required
      ]),
    });
    this.createEventForm = this.formBuilder.group({
      eventName: new FormControl('', []),
      occasion: new FormControl('', []),
      venue: new FormControl('', []),
      date: new FormControl('', []),
      startTime: new FormControl('', []),
      endTime: new FormControl('', []),
      dressCode: new FormControl('', []),
      food: new FormControl('', []),
      transport: new FormControl('', []),
    });
  }
  getEventDetails() {
    // call api for getting event type and occasion and store it in eventType and occasion
  }
  onTypeSubmit() {
    if (this.eventTypeForm.valid) {
      this.createEvent = true;
    } else {
      validateAllFormFields(this.eventTypeForm);
    }
  }
  onEventSubmit() {
    // call api for send invitation and redirect page to landing page
  }
}
