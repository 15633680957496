<ng-template #modal>
  <div class="modal-header">
    <h4 class="modal-title pull-left font-18 latoB navbarGreenFont">title</h4>
    <button type="button" class="close pull-right" aria-label="Close" id="8" (click)="modalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    coming soon
    <div class="text-end  py-3">
      <button class="btn lightGreyBG me-2 font-14" id="5"
        (click)="onClickCancel()">{{ 'commonForm.cancelButton' | translate }}</button>
    </div>
  </div>
</ng-template>
