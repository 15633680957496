import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { Environment } from 'src/environments/environment';
import { RestService } from '../core/rest.service';
import { IAvailabilityPrice } from '../interfaces/availability-price.interface';
import { IProperty } from '../interfaces/property.interface';
import { IPaginationResponse } from '../interfaces/rest-page-response.interface';
import { IRestResponse } from '../interfaces/rest-response.interface';
import { ISubSpot } from '../interfaces/sub-spot.interface';

@Injectable({
  providedIn: 'root'
})
export class PropertyService {

  constructor(private restService: RestService) { }

  public searchProperty(data:any): Observable<IRestResponse<IPaginationResponse<Array<IProperty>>>> {
    return this.restService
      .post(Environment.UrlPath + `/property/home`, data).pipe(
        map((res:any) => res)
        , catchError((error) =>
          throwError(error))
      );
  }

  public getTopSubProperty(data:any): Observable<IRestResponse<IPaginationResponse<Array<ISubSpot>>>> {
    return this.restService.post(Environment.UrlPath +
      `/admin/listing/top-spots`, data, false)
      .pipe(
        map((res:any) => res),
        catchError(error => throwError(error))
      );
  }


  public getPriceForProperty(fromDate: string, toDate: string, stayCategory: string): Observable<IRestResponse<Array<IAvailabilityPrice>>> {
    return this.restService.get(Environment.UrlPath +
      `/property/getlisting-calender`, { fromDate, toDate, stayCategory }, false)
      .pipe(
        map((res:any) => {
          return res;
        }),
        catchError(error => throwError(error))
      );
  }

  public getProperty(id: string): Observable<IRestResponse<IProperty>> {
    return this.restService.get(Environment.UrlPath +
      `/property/getById/${id}`, null, false)
      .pipe(
        map((res: any) => res),
        catchError(error => throwError(error))
      );
  }
}
