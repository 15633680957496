import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { Subject } from 'rxjs';
import { IProperties } from 'src/app/interfaces/listing-types.interface';
import { IGuestData } from 'src/app/interfaces/search-request.interface';
import { HomePageService } from 'src/app/services/home-page.service';

@Component({
  selector: 'app-guest-and-room',
  templateUrl: './guest-and-room.component.html',
  styleUrls: ['./guest-and-room.component.scss']
})
export class GuestAndRoomComponent implements OnInit {

  @Output() formChange = new EventEmitter<any>();
  @Input() guestInputSub!: Subject<IGuestData>;
  guestRoomForm!: FormGroup;
  minValue = 0;
  selectedProperties: IProperties;
  guestInput!: IGuestData;

  constructor(private homePageService: HomePageService) {
    this.selectedProperties = this.homePageService.selectedProperties;
    this.propertiesSubscription();
  }

  ngOnInit(): void {
    this.initGuestRoomForm();
    this.guestDataSubscription();
  }

  guestDataSubscription() {
    this.guestInputSub.subscribe((res: IGuestData) => {
      this.guestInput = res;
      if (this.guestInput) {
        this.setInitValues()
      }
    });
  }

  initGuestRoomForm() {
    this.guestRoomForm = new FormGroup({
      noOfMale: new FormControl({ value: 0, disabled: this.selectedProperties.male ? true : false }),
      noOfFemale: new FormControl({ value: 0, disabled: this.selectedProperties.female ? true : false }),
      noOfChildren: new FormControl({ value: 0, disabled: this.selectedProperties.children ? true : false }),
      noOfRooms: new FormControl({ value: 0, disabled: this.selectedProperties.room ? true : false })
    });

    this.guestRoomForm.valueChanges.subscribe((values) => {
      this.formChange.emit(values);
    });
  }

  setInitValues() {
    this.guestRoomForm.patchValue({
      packOfHours: this.guestInput.packOfHours ? Number(this.guestInput.packOfHours) : 0,
      noOfMale: this.guestInput.noOfMale ? Number(this.guestInput.noOfMale) : 0,
      noOfFemale: this.guestInput.noOfFemale ? Number(this.guestInput.noOfFemale) : 0,
      noOfChildren: this.guestInput.noOfChildren ? Number(this.guestInput.noOfChildren) : 0,
      noOfRooms: this.guestInput.noOfRooms ? Number(this.guestInput.noOfRooms) : 0,
    });
  }

  propertiesSubscription() {
    this.homePageService.propertiesSubjectSubscription().subscribe((res: IProperties) => {
      this.selectedProperties = res;
    });
  }


  onClickMinus(formControlName: string) {
    const value = Number(this.guestRoomForm.get(formControlName)?.value) > 0 ?
      Number(this.guestRoomForm.get(formControlName)?.value) - 1 : 0;
    this.guestRoomForm.get(formControlName)?.setValue(value);
  }

  onClickAdd(formControlName: string) {
    const value = Number(this.guestRoomForm.get(formControlName)?.value) >= 0 ?
      Number(this.guestRoomForm.get(formControlName)?.value) + 1 : 0;
    this.guestRoomForm.get(formControlName)?.setValue(value);
  }
}
