<ng-template #forgotPasswordModal>
    <div class="p-5">
        <div class="orderSummary">
            <header class="modal-title text-white font-16 montserratSB px-4 py-3 text-center">Forgot Password</header>
            <!-- <button type="button" class="close pull-right" aria-label="Close" id="1" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button> -->

            <div *ngIf="toShowEmailId" class="text-center">
                <form [formGroup]="forgotPasswordForm">
                    <p class="font-14  montserratSB pt-3"> </p>
                    <p class="pt-3 pb-4 montserratSB font-12"> Enter Email Id </p>

                    <div class="row-12">
                        <div class=" mb-3 px-3">

                            <input class="w-100 light montserratR px-3 py-4 inputBoxAddUser font-14"
                                formControlName="emailId" type="text" placeholder="Email*">
                            <div class="error red-text " *ngIf="forgotPasswordForm.controls['emailId'].invalid && 
                        forgotPasswordForm.controls['emailId'].dirty">
                                <span *ngIf="forgotPasswordForm.controls['emailId'].errors?.['required']"> 
                                    Please enter your email-id</span>
                                <span *ngIf="forgotPasswordForm.controls['emailId'].touched && 
                                forgotPasswordForm.controls['emailId'].errors?.['email'] && 
                            !forgotPasswordForm.controls['emailId'].errors?.['maxlength'] &&
                            !forgotPasswordForm.controls['emailId'].errors?.['required']">
                                    Please enter your email address in format : Yourname@example.com</span>
                            </div>
                        </div>
                    </div>
                    <div class="text-center py-4 px-4">
                        <button class="font-18 w-100 text-white menuRedBG montserratSB py-3 border-0 submitBtn  pointer"
                            (click)="onClickForgotPassword()">
                            {{'forgotPassword.buttons.getOtp' | translate }}</button>
                    </div>
                </form>
            </div>
            <div *ngIf="toShowOtp" class="text-center">
                <form [formGroup]="otpForm">
                    <p class="font-14  montserratSB pt-3"> </p>
                    <p class="pt-3 pb-4 montserratSB font-12"> Enter your email otp here </p>

                    <div class="row-12">
                        <div class=" mb-3 px-3">

                            <input class="w-100 light montserratR px-3 py-4 inputBoxAddUser font-14"
                                formControlName="otp" type="text" placeholder="OTP*">
                            <div class="error red-text " *ngIf="otpForm.controls['otp'].invalid && 
                                                otpForm.controls['otp'].dirty">
                                <span *ngIf="otpForm.controls['otp'].errors?.['required']">
                                    Please enter your email otp</span>
                            </div>
                        </div>
                    </div>
                    <div class="text-center py-4 px-4">
                        <button class="font-18 w-100 text-white menuRedBG montserratSB py-3 border-0 submitBtn  pointer"
                            (click)="onClickOtpPassword()">
                            Submit</button>
                    </div>
                </form>
            </div>
            <div *ngIf="toShowSetPassword" class="text-center">
                <form [formGroup]="setPasswordForm">
                    <p class="font-14  montserratSB pt-3"> </p>
                    <p class="pt-3 pb-4 montserratSB font-12"> Enter your new password </p>

                    <div class="row-12">
                        <div class=" mb-3 px-3">

                            <input class="w-100 light montserratR px-3 py-4 inputBoxAddUser font-14"
                                formControlName="password" type="password" placeholder="Password*">
                            <div class="error red-text " *ngIf="setPasswordForm.controls['password'].invalid && 
                                                                setPasswordForm.controls ['password'].dirty">
                                <span *ngIf="setPasswordForm.controls['password'].errors?.['required']">
                                    Please enter password</span>
                            </div>
                        </div>
                    </div>
                    <div class="row-12">
                        <div class=" mb-3 px-3">

                            <input class="w-100 light montserratR px-3 py-4 inputBoxAddUser font-14"
                                formControlName="confirmPassword" type="password" placeholder="Confirm Password">
                            <div class="error red-text " *ngIf="setPasswordForm.controls['confirmPassword'].invalid && 
                                                                setPasswordForm.controls['confirmPassword'].dirty">
                                <span *ngIf="setPasswordForm.controls['confirmPassword'].errors?.['required']">
                                    Please enter Confirm Password</span>
                                <span
                                    *ngIf="setPasswordForm.controls['confirmPassword'].touched && 
                                                                    setPasswordForm.controls['confirmPassword'].errors?.['match']">
                                    Password are not match!</span>
                            </div>
                        </div>
                    </div>
                    <div class="text-center py-4 px-4">
                        <button class="font-18 w-100 text-white menuRedBG montserratSB py-3 border-0 submitBtn  pointer"
                            (click)="onClickSetForgotPassword()">
                            Submit</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-template>