import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';


import { RestService } from '../core/rest.service';
import { ICityMaster } from '../interfaces/city-master.interface';
import { ICurrency } from '../interfaces/currency-master.interface';
import { IRestResponse } from '../interfaces/rest-response.interface';
import { IListingType } from '../interfaces/listing-types.interface';
import { ISearchRequest } from '../interfaces/search-request.interface';
import { IAmenity } from '../interfaces/amenity.interface';
import { IPreference } from '../interfaces/preference.interface';
import { Environment } from 'src/environments/environment';

@Injectable()
export class DataService {
  locationData = new Subject<any>();
  searchData = new Subject<ISearchRequest>();
  listingTypes: IListingType[] = [
    {
      listingName: 'Property', listingValue: 'PROPERTY',
      subType: [
        { subTypeName: 'Villa', subTypeValue: 'VILLA' },
        { subTypeName: 'Home Stay', subTypeValue: 'HOME_STAY' },
        { subTypeName: 'Apartment', subTypeValue: 'APARTMENT' },
        { subTypeName: 'Resort', subTypeValue: 'RESORT' },
        { subTypeName: 'Hotel', subTypeValue: 'HOTEL' },
        { subTypeName: 'Farm Stay', subTypeValue: 'FARM_STAY' },
        { subTypeName: 'Beach House', subTypeValue: 'BEACH_HOUSE' },
        { subTypeName: 'Farm Loft', subTypeValue: 'FARM_LOFT' },
        { subTypeName: 'Hostel', subTypeValue: 'HOSTEL' },
        { subTypeName: 'Private Room', subTypeValue: 'PRIVATE_ROOM' },
        { subTypeName: 'Co-working', subTypeValue: 'CO_WORKING' },
        { subTypeName: 'Private Garden', subTypeValue: 'PRIVATE_GARDEN' },
        { subTypeName: 'Function Hall', subTypeValue: 'FUNCTION_HALL' },
        { subTypeName: 'Cruise', subTypeValue: 'CRUISE' },
        { subTypeName: 'Spa', subTypeValue: 'SPA' },
        { subTypeName: 'Creche', subTypeValue: 'CRECHE' },
        { subTypeName: 'Camping Site', subTypeValue: 'CAMPING_SITE' },
        { subTypeName: 'Forest', subTypeValue: 'FOREST' },
        { subTypeName: 'River Side', subTypeValue: 'RIVER_SIDE' },
        { subTypeName: 'Park', subTypeValue: 'PARK' },
        { subTypeName: 'Beach', subTypeValue: 'BEACH' },

      ],
      category: [
        {
          categoryName: 'Luxury Stays', categoryValue: 'LUXURY',
          properties: {
            dateRange: true,
            checkInTime: true,
            checkOutTime: true,
            packOfHours: false,
            male: true,
            female: true,
            children: true,
            infant: true,
            room: true
          },
          typeOfPlace: [
            { key: 'All', value: 'ALL' },
            { key: 'Villa', value: 'VILLA' },
            { key: 'Resort', value: 'RESORT' },
            { key: 'Apartment', value: 'APARTMENT' },
            { key: 'Hotel', value: 'HOTEL' },
            { key: 'Home Stay', value: 'HOME_STAY' },
            { key: 'Beach', value: 'BEACH' },
            { key: 'River Side', value: 'RIVER_SIDE' },
            { key: 'Cruise', value: 'CRUISE' },
            { key: 'Farm Stay', value: 'FARM_STAY' },
            { key: 'Camping Site', value: 'CAMPING_SITE' },
            { key: 'Beach House', value: 'BEACH_HOUSE' },
            { key: 'Park', value: 'PARK' },
            { key: 'Forest', value: 'FOREST' },
            { key: 'Private Garden', value: 'PRIVATE_GARDEN' },
            { key: 'Farm Loft', value: 'FARM_LOFT' },
          ]
        },
        {
          categoryName: 'Budget Stays', categoryValue: 'BUDGET',
          properties: {
            dateRange: true,
            checkInTime: true,
            checkOutTime: true,
            packOfHours: false,
            male: true,
            female: true,
            children: true,
            infant: true,
            room: true
          },
          typeOfPlace: [
            { key: 'All', value: 'ALL' },
            { key: 'Villa', value: 'VILLA' },
            { key: 'Resort', value: 'RESORT' },
            { key: 'Creche', value: 'CRECHE' },
            { key: 'Hotel', value: 'HOTEL' },
            { key: 'Home Stay', value: 'HOME_STAY' },
            { key: 'Co - working', value: 'CO_WORKING' },
            { key: 'Apartment', value: 'APARTMENT' },
            { key: 'Cruise', value: 'CRUISE' },
            { key: 'Farm Stay', value: 'FARM_STAY' },
            { key: 'Camping Site', value: 'CAMPING_SITE' },
            { key: 'Private Room', value: 'PRIVATE_ROOM' },
            { key: 'Farm Loft', value: 'FARM_LOFT' },
            { key: 'Hostel', value: 'HOSTEL' },
          ]
        },
        {
          categoryName: 'Hourly Stays', categoryValue: 'HOURLY',
          properties: {
            dateRange: false,
            checkInTime: true,
            checkOutTime: false,
            packOfHours: true,
            male: true,
            female: true,
            children: false,
            infant: false,
            room: false
          },
          typeOfPlace: [
            { key: 'All', value: 'ALL' },
            { key: 'Villa', value: 'VILLA' },
            { key: 'Resort', value: 'RESORT' },
            { key: 'Home Stay', value: 'HOME_STAY' },
            { key: 'Apartment', value: 'APARTMENT' },
            { key: 'Cruise', value: 'CRUISE' },
            { key: 'Private Room', value: 'PRIVATE_ROOM' },
            { key: 'Hotel', value: 'HOTEL' },
          ]
        },
        {
          categoryName: 'Day Picnic', categoryValue: 'DAY_PICNIC',
          properties: {
            dateRange: false,
            checkInTime: true,
            checkOutTime: false,
            packOfHours: true,
            male: true,
            female: true,
            children: true,
            infant: true,
            room: false
          },
          typeOfPlace: [
            { key: 'All', value: 'ALL' },
            { key: 'Villa', value: 'VILLA' },
            { key: 'Private Garden', value: 'PRIVATE_GARDEN' },
            { key: 'Beach', value: 'BEACH' },
            { key: 'Home Stay', value: 'HOME_STAY' },
            { key: 'Apartment', value: 'APARTMENT' },
            { key: 'Camping Site', value: 'CAMPING_SITE' },
            { key: 'Park', value: 'PARK' },
          ]
        },
        {
          categoryName: 'Special Function', categoryValue: 'SPECIAL_FUNCTION',
          properties: {
            dateRange: false,
            checkInTime: true,
            checkOutTime: false,
            packOfHours: false,
            male: true,
            female: true,
            children: true,
            infant: false,
            room: false
          },
          typeOfPlace: [
            { key: 'All', value: 'ALL' },
            { key: 'Villa', value: 'VILLA' },
            { key: 'Home Stay', value: 'HOME_STAY' },
            { key: 'Function Hall', value: 'FUNCTION_HALL' },
            { key: 'Apartment', value: 'APARTMENT' },
            { key: 'Cruise', value: 'CRUISE' },
            { key: 'Farm Stay', value: 'FARM_STAY' },
          ]
        }
      ],
      properties: { // For property take from each category properties
        dateRange: false,
        checkInTime: false,
        checkOutTime: false,
        packOfHours: false,
        male: false,
        female: false,
        children: false,
        infant: false,
        room: false
      },
      typeOfPlace: [
      ]
    },
    {
      listingName: 'Food And Beverages', listingValue: 'FOOD_AND_BEVERAGES',
      subType: [
        { subTypeName: 'Restaurant', subTypeValue: 'RESTAURANT' },
        { subTypeName: 'Home Kitchen', subTypeValue: 'HOME_KITCHEN' },
        { subTypeName: 'Cafe', subTypeValue: 'CAFE' },
        { subTypeName: 'Bar', subTypeValue: 'BAR' },
        { subTypeName: 'Shop', subTypeValue: 'SHOP' },
      ],
      category: [],
      properties: { //Not using
        dateRange: false,
        checkInTime: false,
        checkOutTime: false,
        packOfHours: false,
        male: false,
        female: false,
        children: false,
        infant: false,
        room: false
      },
      typeOfPlace: [
      ]
    },
    {
      listingName: 'Experience Provider', listingValue: 'EXPERIENCE_PROVIDER',
      subType: [
        { subTypeName: 'All', subTypeValue: 'ALL' },
        { subTypeName: 'Virtual Experience', subTypeValue: 'VIRTUAL_EXPERIENCE' },
        { subTypeName: 'Hobby Classes', subTypeValue: 'HOBBY_CLASSES' },
        { subTypeName: 'Adventure', subTypeValue: 'ADVENTURE' },
        { subTypeName: 'Tour & Cruises', subTypeValue: 'TOUR_AND_CRUISES' },
        { subTypeName: 'Boating', subTypeValue: 'BOATING' },
        { subTypeName: 'Camping', subTypeValue: 'CAMPING' },
        { subTypeName: 'Beauty & Spa', subTypeValue: 'BEAUTY_AND_SPA' },
        { subTypeName: 'Music & Dance', subTypeValue: 'MUSIC_AND_DANCE' },
        { subTypeName: 'Package Tour', subTypeValue: 'PACKAGE_TOUR' },
        { subTypeName: 'Guided Tour', subTypeValue: 'GUIDED_TOUR' },
        { subTypeName: 'Jungle Safari', subTypeValue: 'JUNGLE_SAFARI' },
        { subTypeName: 'Yoga & Wellness', subTypeValue: 'YOGA_AND_WELLNESS' },
        { subTypeName: 'Biking', subTypeValue: 'BIKING' },
        { subTypeName: 'Water Sports', subTypeValue: 'WATER_SPORTS' },
        { subTypeName: 'Trekking', subTypeValue: 'TREKKING' },

      ],
      category: [],
      properties: { //For Experience we need to consider this properties
        dateRange: false,
        checkInTime: true,
        checkOutTime: false,
        packOfHours: true,
        male: true,
        female: true,
        children: true,
        infant: false,
        room: false
      },
      typeOfPlace: [
        { key: 'All', value: 'ALL' },
        { key: 'Beauty & Spa', value: 'BEAUTY_AND_SPA' },
        { key: 'Music & Dance', value: 'MUSIC_AND_DANCE' },
        { key: 'Biking', value: 'BIKING' },
        { key: 'Boating', value: 'BOATING' },
        { key: 'Yoga & Wellness', value: 'YOGA_AND_WELLNESS' },
        { key: 'Virtual Experience', value: 'VIRTUAL_EXPERIENCE' },
        { key: 'Guided Tour', value: 'GUIDED_TOUR' },
        { key: 'Package Tour', value: 'PACKAGE_TOUR' },
        { key: 'Camping', value: 'CAMPING' },
        { key: 'Trekking', value: 'TREKKING' },
        { key: 'Water Sports', value: 'WATER_SPORTS' },
        { key: 'Adventure Sports', value: 'ADVENTURE_SPORTS' },
        { key: 'Jungle Safari', value: 'JUNGLE_SAFARI' },

      ]
    },
    {
      listingName: 'Service Provider', listingValue: 'SERVICE_PROVIDER',
      subType: [
        { subTypeName: 'Transport', subTypeValue: 'TRANSPORT' },
        { subTypeName: 'Driver', subTypeValue: 'DRIVER' },
        { subTypeName: 'Music & Dj', subTypeValue: 'MUSIC_AND_DJ' },
        { subTypeName: 'Beautician', subTypeValue: 'BEAUTICIAN' },
        { subTypeName: 'Home Tutor', subTypeValue: 'HOME_TUTOR' },
        { subTypeName: 'Online Tutor', subTypeValue: 'ONLINE_TUTOR' },
        { subTypeName: 'Nurse & Attender', subTypeValue: 'NURSE_AND_ATTENDER' },
        { subTypeName: 'Online Doctor', subTypeValue: 'ONLINE_DOCTOR' },
        { subTypeName: 'Creche', subTypeValue: 'CRECHE' },
        { subTypeName: 'Cook', subTypeValue: 'COOK' },
        { subTypeName: 'Vet', subTypeValue: 'VET' },
        { subTypeName: 'Waiter', subTypeValue: 'WAITER' },
        { subTypeName: 'House Keeping', subTypeValue: 'HOUSE_KEEPING' },
        { subTypeName: 'Laundry', subTypeValue: 'LAUNDRY' },
        { subTypeName: 'Others', subTypeValue: 'OTHERS' },

      ],
      category: [],
      properties: {//Not using
        dateRange: false,
        checkInTime: true,
        checkOutTime: false,
        packOfHours: false,
        male: false,
        female: false,
        children: false,
        infant: false,
        room: false
      },
      typeOfPlace: [

      ]
    },

  ];
  selectedSearchData!: ISearchRequest;

  constructor(private restService: RestService) { }

  saveLoginData(resArray:any) {
    if (resArray) {
      localStorage.setItem('access_token', resArray.access_token);
      localStorage.setItem('userType', resArray.user_type);
      localStorage.setItem('userId', resArray.user_id);
      localStorage.setItem('userName', resArray.user_name);
      localStorage.setItem('user_role', resArray.user_role);
    }
  }
  clearLoginData(): void {
    localStorage.clear()
  }
  get getUserType() {
    return localStorage.getItem('userType') || null;
  }

  get getUserId() {
    return localStorage.getItem('userId') || null;
  }

  get getUserName() {
    return localStorage.getItem('userName') || null;
  }

  public locationDataSubscriber(): Observable<any> {
    return this.locationData.asObservable();
  }

  public updateLocationData(location:any) {
    this.locationData.next(location);
  }

  public updateSearchData(searchParams: ISearchRequest) {
    this.selectedSearchData = searchParams;
    this.searchData.next(searchParams);
  }

  public searchDataSubscriber(): Observable<ISearchRequest> {
    return this.searchData.asObservable();
  }


  public getCityMaster(): Observable<IRestResponse<Array<ICityMaster>>> {
    return this.restService
      .get(`${Environment.UrlPath}/city`, null).pipe(
        map((res: any) => res)
        , catchError((error) =>
          throwError(error))
      );
  }

  public getCurrencyMaster(): Observable<IRestResponse<Array<ICurrency>>> {
    return this.restService
      .get(`${Environment.UrlPath}/currency`, null).pipe(
        map((res:any) => res)
        , catchError((error) =>
          throwError(error))
      );
  }

  public getAmenitiesMaster(params:any): Observable<IRestResponse<Array<IAmenity>>> {
    return this.restService
      .get(`${Environment.UrlPath}/master/amenities`, params).pipe(
        map((res:any) => res)
        , catchError((error) =>
          throwError(error))
      );
  }

  public getPreferenceMaster(listingType:string): Observable<IRestResponse<Array<IPreference>>> {
    return this.restService
      .get(`${Environment.UrlPath}/master/preference/${listingType}`, null).pipe(
        map((res:any) => res)
        , catchError((error) =>
          throwError(error))
      );
  }

  public localitySearch(keyword: string, listingType: string): Observable<IRestResponse<any>> {
    return this.restService
      .get(`${Environment.UrlPath}/master/locality/hostsearch/${keyword}?listingType=${listingType}`, null).pipe(
        map((res:any) => res)
        , catchError((error) =>
          throwError(error))
      );
  }
}
