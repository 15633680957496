<div class="input-group menu-search search-component">
  <div class="" *ngIf="!showFoodServiceMenu">
    <form [formGroup]="searchForm" class="">
      <div class="d-flex justify-content-center">
        <div class="">
          <mat-form-field appearance="fill" class="w-100 type-of-stay font-13">
            <mat-select class="text-black fw-bold" formControlName="stayCategory"
              (selectionChange)="categoryOptions($event.value)">
              <mat-option *ngFor="let item of listingOptions" [value]="item.value">
                {{ item.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="font-13">
          <input type="text" matInput placeholder="{{
                'commonForm.filterDefaultOption.location' | translate
              }}" class="montserratL location-search font-13 border-left-grey px-2" formControlName="keyword"
            [matAutocomplete]="auto" />
          <mat-autocomplete #auto="matAutocomplete" class="locationList">
            <mat-option *ngFor="let option of localities" [value]="option.localityName">
              {{ option.localityName }}
            </mat-option>
          </mat-autocomplete>
        </div>
        <div class="">
          <div class="example-full-width px-2 montserratL date-input inputMat border-left-grey maxWidth190">
            <!-- <mat-label class="montserratB text-dark mt-1 mb-0">{{ 'commonForm.filterName.checkIn' | translate }}
        </mat-label> -->
            <div class="btn-group calendar-dropdown  open show" dropdown #dropdown="bs-dropdown" [autoClose]="true">
              <button id="button-basic" dropdownToggle type="button" aria-controls="dropdown-basic"
                class="border-0 bg-transparent date-btn placeholder-font text-black font-13"
                (click)="autoClose = !autoClose"
                >
                <ng-container *ngIf="
                  searchForm.get('checkIn')?.value ||
                    searchForm.get('checkOut')?.value;
                  else elseTemplate
                ">
                  {{ searchForm.get("checkIn")?.value | dateAndTime }}
                  {{
                  searchForm.get("checkIn")?.value &&
                  searchForm.get("checkOut")?.value
                  ? "-"
                  : ""
                  }}
                  {{ searchForm.get("checkOut")?.value | dateAndTime }}
                </ng-container>
                <ng-template #elseTemplate> Add date and time </ng-template>
              </button>
              <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu show" role="menu" aria-labelledby="button-basic"
                style="width: max-content" (click)="preventClose($event)" [ngClass]="{
                  toHideCalender: autoClose ? true : false,
                  toShowCalender: !autoClose ? true : false
                }" >
                <!-- UI need to be implemented -->
                <app-date-picker [dateInput]="selectedRange" [properties]="selectedProperties"
                  (selectedDatesEvent)="setDateRange($event)" (selectedTimeEvent)="setTimeRange($event)">
                </app-date-picker>
              </ul>
            </div>

            <input hidden="true" formControlName="checkIn" value="" />
          </div>
        </div>

        <div class="">
          <div class="example-full-width px-2 montserratL date-input inputMat border-left-grey maxWidth190 font-13">
            <div class="btn-group" dropdown #dropdown="bs-dropdown" [autoClose]="true">
              <button id="button-basic1" dropdownToggle type="button" aria-controls="dropdown-basic1"
                class="border-0 bg-transparent date-btn text-nowrap pe-5 placeholder-font text-black font-13"
                (click)="onUpdateGuestData()">
                <ng-container *ngIf="setGuestDisplayValue(); else elseTemplate1">
                  {{ setGuestDisplayValue() }}
                </ng-container>
                <ng-template #elseTemplate1>
                  {{ "commonForm.filterDefaultOption.guestAndRooms" | translate }}
                </ng-template>
              </button>
              <ul id="dropdown-basic1" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic1"
                (click)="preventClose($event)" style="width: max-content">
                <!-- UI need to be implemented -->
                <app-guest-and-room [guestInputSub]="guestData" (formChange)="onChangeGuestRoomForm($event)">
                </app-guest-and-room>
              </ul>
            </div>
          </div>
        </div>
        <div class="">
          <img alt="search" src="../../assets/img/search.jpg" class="float-right searchIcon pointer"
            (click)="onSearch()" />
        </div>
      </div>

    </form>
  </div>
  <div class="w-100" *ngIf="showFoodServiceMenu">
    <form [formGroup]="searchForServiceAndFoodForm">
      <div class="row align-items-center justify-content-center">
        <div class="col-7 col-md-7 col-xxl-7 border-right-grey">
          <input type="text" (focus)="onPickUpLocation()" id="menuPickUpLocation"
            class="w-100 outline-0 border-0 bg-transparent pad-15 font-13" placeholder="Enter a location"
            formControlName="pickUpLocation" />
        </div>
        <!-- <div class="col-3 border-right-grey">
                    <mat-form-field appearance="fill" class="example-full-width montserratSB w-100" floatLabel="always" disableRipple="true">
                        <mat-label class="montserratB text-dark">
              {{ 'commonForm.filterName.location' | translate }}
            </mat-label>
                        <mat-select formControlName="keyword" placeholder="{{ 'commonForm.filterDefaultOption.location' | translate }}" class="montserratL">
                            <mat-option *ngFor="let city of cities" [value]="city.city">
                                {{city.city}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div> -->
        <div class="col-4 col-md-4 col-xxl-4">
          <div class="example-full-width px-2 montserratL inputMat maxWidth190">
            <!-- <mat-label class="montserratB text-dark mt-1 mb-0">{{ 'commonForm.filterName.checkIn' | translate }}
      </mat-label> -->
            <div class="btn-group" dropdown #dropdown="bs-dropdown" [autoClose]="true">
              <button id="button-basic-1" dropdownToggle type="button" aria-controls="dropdown-basic-1"
                class="border-0 bg-transparent date-btn ont-12">
                <ng-container *ngIf="
                    searchForServiceAndFoodForm.get('checkIn')?.value;
                    else elseTemplate
                  ">
                  <span>
                    {{
                    searchForServiceAndFoodForm.get("checkIn")?.value
                    | dateAndTime
                    }}</span>
                </ng-container>
                <ng-template #elseTemplate>
                  <span>Add date and Time</span>
                </ng-template>
              </button>
              <ul id="dropdown-basic-1" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="button-basic-1"
                style="width: max-content" (click)="preventClose($event)">
                <!-- UI need to be implemented -->
                <app-date-picker [properties]="selectedProperties" (selectedDatesEvent)="setDateRange1($event)"
                  (selectedTimeEvent)="setTimeRange1($event)" [conf]="config">
                </app-date-picker>
              </ul>
            </div>

            <input hidden="true" formControlName="checkIn" value="" />
          </div>
        </div>
        <div class="col-1">
          <img (click)="onSearchFoodAndService()" alt="search" src="../../assets/img/search.jpg"
            class="float-right searchIcon pointer" />
        </div>
      </div>
    </form>
  </div>
</div>
