<div class="spaceFromTop"></div>
<div class="container-fluid" id="foodOrderSection1">
    <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <h5 class="font-24 montserratR pb-1 mb-0">Hungry? Select food and drinks </h5>
            <label class="text-dark montserratL font-16">we will deliver at the address & time provided</label>
        </div>
    </div>
    <div class="row pb-5 pt-2" id="foodOrderSection2">
        <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-12">
            <div class="position-relative text-center mb-3">
                <img alt="South Indian" src="../../../assets/img/south-indian.jpg" class="img-fluid w-100" />
                <div class="position-absolute py-2">
                    <label class="rounded-circle marginLast label-1">
                        <div class="priceLabel">
                            <div class="blackjar text-white font-36 bold">
                                South<br />Indian
                            </div>
                            <div class="font-10 montserratR text-white priceLabelText">Local Restaurant</div>
                        </div>
                    </label>
                </div>
            </div>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-12">
            <div class="position-relative text-center mb-3">
                <img alt="Luxury Stay" src="../../../assets/img/punjabi.jpg" class="img-fluid w-100" />
                <div class="position-absolute py-2">
                    <label class="rounded-circle ">
                        <div class="priceLabelSingleLine priceLabel">
                            <div class="blackjar text-white font-36 bold">
                                Punjabi
                            </div>
                            <div class="font-10 montserratR text-white priceLabelText">Local Restaurant</div>
                        </div>
                    </label>
                </div>
            </div>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-12">
            <div class="position-relative text-center mb-3">
                <img alt="Luxury Stay" src="../../../assets/img/local-speciality.jpg" class="img-fluid w-100" />
                <div class="position-absolute py-2">
                    <label class="rounded-circle ">
                        <div class="priceLabel">
                            <div class="blackjar text-white font-36 bold">
                                Local<br />Speciality
                            </div>
                            <div class="font-10 montserratR text-white priceLabelText">Home Kitchen</div>
                        </div>
                    </label>
                </div>
            </div>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-12">
            <div class="position-relative text-center mb-3">
                <img alt="Luxury Stay" src="../../../assets/img/bitesanddrinks.jpg" class="img-fluid w-100" />
                <div class="position-absolute py-2">
                    <label class="rounded-circle ">
                        <div class="priceLabel">
                            <div class="blackjar text-white font-36 bold">
                                Bites &<br />Drinks
                            </div>
                            <div class="font-10 montserratR text-white priceLabelText">Local Restaurant</div>
                        </div>
                    </label>
                </div>
            </div>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-12">
            <div class="position-relative text-center mb-3">
                <img alt="Luxury Stay" src="../../../assets/img/vegetarian.jpg" class="img-fluid w-100" />
                <div class="position-absolute py-2">
                    <label class="rounded-circle ">
                        <div class="priceLabelSingleLine priceLabel">
                            <div class="blackjar text-white font-36 bold">
                                Vegetarian
                            </div>
                            <div class="font-10 montserratR text-white priceLabelText">Local Restaurant</div>
                        </div>
                    </label>
                </div>
            </div>
        </div>
        <div class="col-xl-2 col-lg-2 col-md-4 col-sm-6 col-12">
            <div class="position-relative text-center mb-3">
                <img alt="Luxury Stay" src="../../../assets/img/nonvegetarian.jpg" class="img-fluid w-100" />
                <div class="position-absolute py-2">
                    <label class="rounded-circle ">
                        <div class="priceLabel">
                            <div class="blackjar text-white font-36 bold">
                                Non<br />Vegetarian
                            </div>
                            <div class="font-10 montserratR text-white priceLabelText">Local Restaurant</div>
                        </div>
                    </label>
                </div>
            </div>
        </div>
    </div>
    <div class="clearfix"></div>
    <div class="row">
        <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
            <h5 class="font-24 montserratR pb-1 mb-0">Pre Order Your Food</h5>
            <label class="text-dark montserratL font-16">Selected menu from favourite restaurants</label>
        </div>
    </div>
    <div class="row pb-5 pt-2" id="foodOrderTab">
        <div class="col-xl-9 col-lg-9 col-md-9 col-sm-12 col-12">
            <tabset [justified]="true">
                <tab id="breakfast">
                    <ng-template tabHeading>
                        <div class="row">
                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-5"> <img alt="Breakfast"
                                    src="../../assets/img/breakfast.png" class="tabIcon" /></div>
                            <div class="col-xl-9 col-lg-9 col-md-9 col-sm-8 col-12 text-start">
                                Breakfast
                                <div class="montserratR font-12 pt-1">9 AM-12 PM</div>
                            </div>
                        </div>
                    </ng-template>
                    <div class="row" id="tabContent">
                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                            <div class="row my-3">
                                <div class="col-xl-1">
                                    <img src="../../../assets/img/veg.png" />
                                </div>
                                <div class="col-xl-7 montserratR font-14">
                                    <label>Idli (2 Pcs)</label>
                                    <div><img alt="rupee" src="../../../assets/img/rupee.png" class="me-1" />
                                        <span>50</span>
                                    </div>
                                    <p class="font-12 montserratL pt-1">Lorem ipsum dolor sit amet, consectetur
                                        adipiscing elit</p>
                                </div>
                                <div class="col-xl-4">
                                    <img alt="breakfast" src="../../../assets/img/idli.png" class="img-fluid w-100" />
                                </div>
                            </div>
                            <div class="row my-3">
                                <div class="col-xl-1">
                                    <img src="../../../assets/img/veg.png" />
                                </div>
                                <div class="col-xl-7 montserratR font-14">
                                    <label>Idli (2 Pcs)</label>
                                    <div><img alt="rupee" src="../../../assets/img/rupee.png" class="me-1" />
                                        <span>50</span>
                                    </div>
                                    <p class="font-12 montserratL pt-1">Lorem ipsum dolor sit amet, consectetur
                                        adipiscing elit</p>
                                </div>
                                <div class="col-xl-4">
                                    <img alt="breakfast" src="../../../assets/img/idli.png" class="img-fluid w-100" />
                                </div>
                            </div>
                        </div>

                        <div class="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                            <div class="row my-3">
                                <div class="col-xl-1">
                                    <img src="../../../assets/img/veg.png" />
                                </div>
                                <div class="col-xl-7 montserratR font-14">
                                    <label>Idli (2 Pcs)</label>
                                    <div><img alt="rupee" src="../../../assets/img/rupee.png" class="me-1" />
                                        <span>50</span>
                                    </div>
                                    <p class="font-12 montserratL pt-1">Lorem ipsum dolor sit amet, consectetur
                                        adipiscing elit</p>
                                </div>
                                <div class="col-xl-4">
                                    <img alt="breakfast" src="../../../assets/img/idli.png" class="img-fluid w-100" />
                                </div>
                            </div>
                            <div class="row my-3">
                                <div class="col-xl-1">
                                    <img src="../../../assets/img/veg.png" />
                                </div>
                                <div class="col-xl-7 montserratR font-14">
                                    <label>Idli (2 Pcs)</label>
                                    <div><img alt="rupee" src="../../../assets/img/rupee.png" class="me-1" />
                                        <span>50</span>
                                    </div>
                                    <p class="font-12 montserratL pt-1">Lorem ipsum dolor sit amet, consectetur
                                        adipiscing elit</p>
                                </div>
                                <div class="col-xl-4">
                                    <img alt="breakfast" src="../../../assets/img/idli.png" class="img-fluid w-100" />
                                </div>
                            </div>
                        </div>
                    </div>
                </tab>
                <tab id="lunch">
                    <ng-template tabHeading>
                        <div class="row">
                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-5"> <img alt="Lunch"
                                    src="../../assets/img/lunch.png" class="tabIcon" /></div>
                            <div class="col-xl-9 col-lg-9 col-md-9 col-sm-8 col-12 text-start">Lunch
                                <div class="montserratR font-12 pt-1">12 PM-3 PM</div>
                            </div>
                        </div>
                    </ng-template>
                    122134
                </tab>
                <tab id="snacks">
                    <ng-template tabHeading>
                        <div class="row">
                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-5"><img alt="Snacks"
                                    src="../../assets/img/snacks.png" class="tabIcon" /></div>
                            <div class="col-xl-9 col-lg-9 col-md-9 col-sm-8 col-12 text-start">Snacks
                                <div class="montserratR font-12 pt-1">3 PM-7 PM</div>
                            </div>

                        </div>
                    </ng-template>
                    122134
                </tab>
                <tab id="dinner">
                    <ng-template tabHeading>
                        <div class="row">
                            <div class="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-5"><img alt="Dinner"
                                    src="../../assets/img/dinner.png" class="tabIcon" />
                            </div>
                            <div class="col-xl-9 col-lg-9 col-md-9 col-sm-8 col-12 text-start">Dinner
                                <div class="montserratR font-12 pt-1">8 PM-10 PM</div>
                            </div>
                        </div>
                    </ng-template>
                    122134
                </tab>
            </tabset>
        </div>
        <div class="col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12">
            <app-order-summary></app-order-summary>
        </div>
    </div>
</div>