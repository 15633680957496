import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ComingSoonComponent } from './coming-soon/coming-soon.component';
import { GuestAndRoomComponent } from './guest-and-room/guest-and-room.component';
import { SharedModule } from '../shared/shared.module';
import { DatePickerComponent } from './date-picker/date-picker.component';
import { TimePickerComponent } from './time-picker/time-picker.component';
import { PropertyDetailCardComponent } from './property-detail-card/property-detail-card.component';
import { PropertyPlaceCardComponent } from './property-place-card/property-place-card.component';
import { LoginModalComponent } from './login-modal/login-modal.component';
import { VerifyOtpModalComponent } from './verify-otp-modal/verify-otp-modal.component';
import { RegisterModalComponent } from './register-modal/register-modal.component';
import { PaginationComponent } from './pagination/pagination.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { FormsModule } from '@angular/forms';



@NgModule({
  declarations: [
    ComingSoonComponent,
    GuestAndRoomComponent,
    DatePickerComponent,
    TimePickerComponent,
    PropertyPlaceCardComponent,
    PropertyDetailCardComponent,
    LoginModalComponent,
    VerifyOtpModalComponent,
    RegisterModalComponent,
    PaginationComponent,
    ForgotPasswordComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    FormsModule
  ],
  exports: [
    ComingSoonComponent,
    GuestAndRoomComponent,
    DatePickerComponent,
    TimePickerComponent,
    PropertyPlaceCardComponent,
    PropertyDetailCardComponent,
    LoginModalComponent,
    VerifyOtpModalComponent,
    RegisterModalComponent,
    PaginationComponent,
  ]
})
export class CommonComponentModule { }
