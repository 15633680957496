import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-food-order',
  templateUrl: './food-order.component.html',
  styleUrls: ['./food-order.component.scss']
})
export class FoodOrderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
