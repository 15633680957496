
export class Environment{

  public static baseUrl = 'https://api.bookmyloft.com';
  public static UrlPath = Environment.baseUrl + '/api/v1';
  public static facebookAppId = '533117244284385';
  public static googleMapApiKey = 'AIzaSyCIjLMvywsEm6nHjVTPMk67s1xYuBNowwM';
  public static clientName = 'bookmyloft';
  public static clientPassword = 'LiQf@TP82c#Q';
  public static razorPayApiUrl = 'https://api.razorpay.com/v1';
  public static razorPayKey = 'rzp_test_XNSUZmHLP92f7b';
  public static razorPaySecretKey = 'rzp_test_XNSUZmHLP92f7b';
  public static domainUrl = "https://www.bookmyloft.com"
}
