import { Component, OnInit, TemplateRef } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';
import { CustomValidators } from '../validators/custom.validator';
import { validateAllFormFields } from '../utils/validateform';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { sha256 } from 'js-sha256';
import { DataService } from '../services';
import { Environment } from 'src/environments/environment';
declare const FB: any;
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  providers: [AuthService]
})
export class LoginComponent implements OnInit {
  forgotPassword!: BsModalRef;
  public loginForm!: FormGroup;
  public forgotPasswordForm!: FormGroup;
  constructor(
    private authService: AuthService,
    private formBuilder: FormBuilder,
    private router: Router,
    private modalService: BsModalService,
    private dataService: DataService
  ) {
    this.initFacebook();
  }

  ngOnInit() {
    this.loginForm = this.formBuilder.group({
      email: new FormControl(null, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),
        CustomValidators.email]),
      password: new FormControl('', [
          Validators.required,
          Validators.minLength(5),
          Validators.maxLength(8),
          // as abilash credentials are 12345 commented below code
          // CustomValidators.oneDigitValidator,
          // CustomValidators.oneSpecialCharValidator,
          // CustomValidators.uppercaseValidator
      ]),
    }),
      this.forgotPasswordForm = this.formBuilder.group({
        emailAddress: ['',
          Validators.compose([
            Validators.required,
            CustomValidators.email
          ])]
      });
  }
  initFacebook() {
    FB.init({
      appId: Environment.facebookAppId,
      cookie: false,
      xfbml: false,
      version: 'v6.0'
    });
    FB.AppEvents.logPageView();
  }
  onClickFbLogin() {
    FB.login((result:any) => {
      if (result.status === 'connected') {
        this.router.navigate(['/dashboard'], { queryParams: { id: result.authResponse.userID } });
      }
    });
  }
  onClickForgetPasswordModal(template: TemplateRef<any>) {
    this.forgotPassword = this.modalService.show(template);
  }
  onClickResetPassword() {
    if (this.forgotPasswordForm.valid) {
      this.authService.getForgotPasswordEmail(this.forgotPasswordForm.value.emailAddress).subscribe((res: any) => {
        if (res.status.code === 200) {
          this.forgotPassword.hide();
          this.router.navigate(['/register/forgot-password'],
            { queryParams: { email: this.forgotPasswordForm.value.emailAddress, id: res.response.token } });
        }
      });
    }
  }
  onClickLogin() {
    if (this.loginForm.valid) {
      const data = {
        username: this.loginForm.value.email,
        password: sha256(this.loginForm.value.password),
        grant_type: 'password'
      };

      this.authService.login(data).subscribe((res: any) => {
        this.dataService.saveLoginData(res.response);
        this.router.navigate(['/home']);
      });
    } else {
      validateAllFormFields(this.loginForm);
    }
  }
}

