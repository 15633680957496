import { Injectable } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { IProperties } from '../interfaces/listing-types.interface';

@Injectable({
  providedIn: 'root'
})
export class HomePageService {

  private categorySubject = new Subject<string>();
  private propertiesSubject = new Subject<IProperties>();
  selectedProperties!: IProperties;
  selectedCategory: string='';
  private onCloseCalender = new Subject<string>();

  constructor() { }

  updateCategorySubject(value:any): void {
    this.selectedCategory = value;
    this.categorySubject.next(value);
  }
  closeCalender(): void {
    this.onCloseCalender.next('');
  }
  closeCalenderSubscription(): Observable<string> {
    return this.onCloseCalender.asObservable();
  }
  categorySubjectSubscription(): Observable<string> {
    return this.categorySubject.asObservable();
  }

  updatePropertiesSubject(value: any): void {
    this.selectedProperties = value;
    this.propertiesSubject.next(value);
  }

  propertiesSubjectSubscription(): Observable<IProperties> {
    return this.propertiesSubject.asObservable();
  }

  getCategoryAsString(value: string): string {
    switch (value) {
      case 'Luxury Stays':
        return 'LUXURY';
      case 'Budget Stays':
        return 'BUDGET';
      case 'Hourly Stays':
        return 'HOURLY';
      case 'Special Function':
        return 'SPECIAL_FUNCTION';
      case 'Day Picnic':
        return 'DAY_PICNIC';
      case 'Experience Provider':
        return 'EXPERIENCE_PROVIDER';
      default:
        return 'LUXURY'
    }
  }
}
