
import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as moment from 'moment';
import { Subject, Subscription } from 'rxjs';
import { ICityMaster } from 'src/app/interfaces/city-master.interface';
import { IProperties } from 'src/app/interfaces/listing-types.interface';
import { ILocality } from 'src/app/interfaces/locality.interface';
import { IRestResponse } from 'src/app/interfaces/rest-response.interface';
import { IGuestData, ITimeData } from 'src/app/interfaces/search-request.interface';
import { DataService } from 'src/app/services';
import { HomePageService } from 'src/app/services/home-page.service';
import { UtilityService } from 'src/app/services/utility.service';

@Component({
  selector: 'app-search',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss']
})
export class SearchComponent implements OnInit {
  searchForm!: FormGroup;
  searchForServiceAndFoodForm!: FormGroup;
  @Input() showFoodServiceMenu = false;
  cities: ICityMaster[] = [];
  filteredOptions!: any;
  showGuest = false;
  listingOptions = [
    { name: 'Luxury Stays', value: 'Luxury Stays' },
    { name: 'Budget Stays', value: 'Budget Stays' },
    { name: 'Hourly Stays', value: 'Hourly Stays' },
    { name: 'Day Picnic', value: 'Day Picnic' },
    { name: 'Special Function', value: 'Special Function' },
    { name: 'Experience Provider', value: 'Experience Provider' },
  ];
  selectedCategory: string='';
  EXPERIENCE_PROVIDER = 'Experience Provider';
  selectedProperties!: IProperties;
  selectedRange: Array<moment.Moment> = [];
  guestData: Subject<IGuestData> = new Subject<IGuestData>();
  date = new Date();
  startDate: Date | string = this.date;
  endDate: Date | string = this.date;
  startTime = new Date(this.date.setHours(9, 0, 0));
  endTime = new Date(this.date.setHours(9, 0, 0));
  packOfHours: string='';
  menuPickUpLocationAddress!: Subscription;
  menuPickUpLocationCoordinates: { longitude: number, latitude: number, zoom: number, location: '' } = { latitude: 0, location: '', longitude: 0, zoom: 0 };
  localities: ILocality[]=[];
  val: any;
  firstLoad = false;
  autoClose = true;
  config = {
    localeString: 'en',
    showItems: 1,
    showSlides: false
  };
  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private dataService: DataService,
    private utilityService: UtilityService,
    private homePageService: HomePageService,
  ) {
    // This to trigger only category subscription for first time
    this.route.snapshot.queryParamMap.get('stayCategory') ?
      this.homePageService.updateCategorySubject(this.route.snapshot.queryParamMap.get('stayCategory')) : null;
    this.firstLoad = true; //flag to maintain init call
  }

  ngOnInit(): void {
    this.getCityMaster();
    this.initSearchForm();
    this.initSearchForServiceAndFoodForm();
    this.paramMapSubscription();
    this.onCalenderClose();
  }
  onCalenderClose(): void {
    this.homePageService.closeCalenderSubscription().subscribe((res) => {
      this.autoClose = true;
    })
  }
  getCityMaster() {
    this.dataService.getCityMaster().subscribe((res: IRestResponse<Array<ICityMaster>>) => {
      this.cities = res.response;
    });
  }

  initSearchForm(): void {
    this.searchForm = this.formBuilder.group({
      keyword: this.formBuilder.control(''),
      propertyType: this.formBuilder.control(''),
      checkIn: this.formBuilder.control(''),
      checkOut: this.formBuilder.control(''),
      packOfHours: this.formBuilder.control(''),
      noOfMale: this.formBuilder.control(''),
      noOfFemale: this.formBuilder.control(''),
      noOfChildren: this.formBuilder.control(''),
      noOfRooms: this.formBuilder.control(''),
      status: this.formBuilder.control('Active'),
      stayCategory: this.formBuilder.control('Luxury Stays'),
      pickUpLocation: [''],
      location: [''],
      pickUpCoordinates: ['']
    });

    this.formValueChanges();
  }
  initSearchForServiceAndFoodForm(): void {
    this.searchForServiceAndFoodForm = this.formBuilder.group({
      checkIn: this.formBuilder.control(''),
      status: this.formBuilder.control('Active'),
      pickUpLocation: [''],
      pickUpCoordinates: ['']
    });
    this.paramMapSubscription();
  }
  formValueChanges() {
    this.searchForm.get('stayCategory')?.valueChanges.subscribe((value) => {
      this.selectedCategory = value;
      const queryParams: Params = { stayCategory: value }
      this.router.navigate(
        [],
        {
          relativeTo: this.route,
          queryParams: queryParams,
          queryParamsHandling: 'merge'
        });
      this.initSearchForm();
      this.homePageService.updateCategorySubject(value);
      this.getProperties(value);
    });

    this.searchForm.get('keyword')?.valueChanges.subscribe((value) => {
      this.dataService.updateLocationData(value);
      const locationType = this.selectedCategory === 'Experience Provider' ? 'EXPERIENCE_PROVIDER' : 'PROPERTY';
      this.dataService.localitySearch(value, locationType).subscribe((res: IRestResponse<Array<ILocality>>) => {
        this.localities = res.response;
        this.filteredOptions = this._filter(value);
      });
    });
  }

  categoryOptions(val:any) {
    this.homePageService.updateCategorySubject(val);
    this.getProperties(val);
  }


  private _filter(name: string): ILocality[] {
    const filterValue = name.toLowerCase();
    return this.localities.filter(option => option.localityName.toLowerCase().includes(filterValue));
  }

  onUpdateGuestData() {
    const params = this.searchForm.value;
    if (this.firstLoad) {
      setTimeout(() => {
        this.guestData.next({
          packOfHours: this.utilityService.ifExists(params.packOfHours),
          noOfMale: this.utilityService.ifExists(params.noOfMale),
          noOfFemale: this.utilityService.ifExists(params.noOfFemale),
          noOfChildren: this.utilityService.ifExists(params.noOfChildren),
          noOfRooms: this.utilityService.ifExists(params.noOfRooms),
        });
        this.firstLoad = false;
      }, 100);
    }
  }

  paramMapSubscription() {

    this.route.queryParamMap.subscribe((params:any) => {
      params.get("stayCategory") ? this.getProperties(params.get("stayCategory")) : null;
      params.get("checkIn") || params.get("checkOut") ? this.getDateAndTime(params.get("checkIn"), params.get("checkOut")) : null;

      this.guestData.next({
        packOfHours: this.utilityService.ifExists(params.get("packOfHours")),
        noOfMale: this.utilityService.ifExists(params.get("noOfMale")),
        noOfFemale: this.utilityService.ifExists(params.get("noOfFemale")),
        noOfChildren: this.utilityService.ifExists(params.get("noOfChildren")),
        noOfRooms: this.utilityService.ifExists(params.get("noOfRooms")),
      });
      let checkIn = params.get("checkIn") && params.get("checkIn") !== 'null' ? params.get("checkIn") : ''
      let checkOut = params.get("checkOut") && params.get("checkOut") !== 'null' ? params.get("checkOut") : ''
      this.searchForm.patchValue({
        keyword: this.utilityService.ifExists(params.get("keyword")),
        propertyType: this.utilityService.ifExists(params.get("propertyType")),
        checkIn: this.utilityService.ifExists(checkIn),
        checkOut: this.utilityService.ifExists(checkOut),
        packOfHours: this.utilityService.ifExists(params.get("packOfHours")),
        noOfMale: this.utilityService.ifExists(params.get("noOfMale")),
        noOfFemale: this.utilityService.ifExists(params.get("noOfFemale")),
        noOfChildren: this.utilityService.ifExists(params.get("noOfChildren")),
        noOfRooms: this.utilityService.ifExists(params.get("noOfRooms")),
        stayCategory: this.utilityService.ifExists(params.get("stayCategory")),
        pickUpLocation: this.utilityService.ifExists(params.get("pickUpLocation")),
        location: this.utilityService.ifExists(params.get("location")),
        pickUpCoordinates: params.get('pickUpCoordinates') ? params.get('pickUpCoordinates') : ''
      }, { emitEvent: false });

      this.searchForServiceAndFoodForm.patchValue({
        checkIn: this.utilityService.ifExists(checkIn),
        pickUpLocation: this.utilityService.ifExists(params.get("pickUpLocation")),
        location: this.utilityService.ifExists(params.get("location")),
        pickUpCoordinates: params.get('pickUpCoordinates') ? params.get('pickUpCoordinates') : ''
      }, { emitEvent: false });
    });
  }

  updateQueryParams(searchData: any) {

    this.dataService.updateSearchData(searchData);
    const queryParams: Params = searchData;
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams: queryParams,
        queryParamsHandling: 'merge'
      });
  }


  getDateAndTime(checkInDate: string, checkOutDate: string) {
    const checkIn = checkInDate ? moment(moment(checkInDate).format("DD/MM/YYYY hh:mm:ss")) : moment(new Date());
    const checkOut = checkOutDate ? moment(moment(checkOutDate).format("DD/MM/YYYY hh:mm:ss")) : moment(new Date());
  }


  getProperties(value:any) {
    if (value === this.EXPERIENCE_PROVIDER) {
      const listing = this.dataService.listingTypes[2];
      this.selectedProperties = listing.properties;
      this.homePageService.updatePropertiesSubject(listing.properties);
    } else {
      const listing = this.dataService.listingTypes[0];
      const selectedCategory = listing.category.find((ele) => ele.categoryName === value);
      if (selectedCategory != undefined) {
        this.selectedProperties = selectedCategory.properties;
        this.homePageService.updatePropertiesSubject(selectedCategory.properties);
      }

    }
  }

  setDateRange(dateArray: Array<any>): void {

    if (dateArray.length > 0) {
      this.selectedRange = [];
      this.startDate = dateArray.length > 0 ? new Date(moment(dateArray[0]).toDate()) : this.date;
      this.endDate = dateArray.length > 1 ? new Date(moment(dateArray[1]).toDate()) : '';

      const startDateTime = this.startDate ? new Date((this.startDate as Date).setHours(new Date(this.startTime).getHours(),
        new Date(this.startTime).getMinutes(), new Date(this.startTime).getSeconds())) : '';
      const endDateTime = this.endDate ? new Date((this.endDate as Date).setHours(new Date(this.endTime).getHours(),
        new Date(this.endTime).getMinutes(), new Date(this.endTime).getSeconds())) : '';

      this.patchDateAndTime(startDateTime, endDateTime);
    }
  }
  setDateRange1(dateArray: Array<any>): void {
    if (dateArray.length > 0) {
      this.selectedRange = [];
      this.startDate = dateArray.length > 0 ? new Date(moment(dateArray[0]).toDate()) : new Date();
      this.endDate = dateArray.length > 1 ? new Date(moment(dateArray[1]).toDate()) : '';

      const checkIn = this.startDate ? new Date((this.startDate as Date).setHours(new Date(this.startTime).getHours(),
        new Date(this.startTime).getMinutes(), new Date(this.startTime).getSeconds())) : '';
      this.searchForServiceAndFoodForm.patchValue({
        checkIn: checkIn
      });
      this.autoClose = true;
    }
  }
  setTimeRange(timeData: ITimeData) {
    this.packOfHours = timeData.packOfHours ? timeData.packOfHours : '';
    this.startTime = timeData.checkInTime ? timeData.checkInTime : this.startTime;
    this.endTime = timeData.checkOutTime ? timeData.checkOutTime : this.endTime;
    const startDateTime = new Date((this.startDate as Date).setHours(new Date(this.startTime).getHours(),
      new Date(this.startTime).getMinutes(), new Date(this.startTime).getSeconds()));
    const endDateTime = timeData.checkOutTime ? new Date((this.endDate as Date).setHours(new Date(this.endTime).getHours(),
      new Date(this.endTime).getMinutes(), new Date(this.endTime).getSeconds())) : '';

    this.patchDateAndTime(startDateTime, endDateTime, this.packOfHours);
  }
  setTimeRange1(timeData: ITimeData) {
    this.startTime = timeData.checkInTime ? timeData.checkInTime : this.startTime;
    const checkIn = new Date((this.startDate as Date).setHours(new Date(this.startTime).getHours(),
      new Date(this.startTime).getMinutes(), new Date(this.startTime).getSeconds()));

    this.searchForServiceAndFoodForm.patchValue({
      checkIn: checkIn
    });
  }
  patchDateAndTime(checkIn: Date | string, checkOut: Date | string, packOfHours?: string) {

    this.searchForm.patchValue({
      checkIn,
      checkOut,
      packOfHours
    });
  }
  onPickUpLocation(): void {
    this.utilityService.searchByLocation('menuPickUpLocation');
    this.menuPickUpLocationAddress = this.utilityService.locationSearch.subscribe((res) => {
      this.menuPickUpLocationCoordinates = { latitude: res.latitude, longitude: res.longitude, zoom: res.zoom, location: res.location };
      this.searchForm.patchValue({
        pickUpCoordinates: JSON.stringify(this.menuPickUpLocationCoordinates),
        pickUpLocation: res.location
      });
      this.searchForServiceAndFoodForm.patchValue({
        pickUpCoordinates: JSON.stringify(this.menuPickUpLocationCoordinates),
        pickUpLocation: res.location
      });
      this.menuPickUpLocationAddress.unsubscribe();
    });
  }
  onChangeGuestRoomForm(values:any) {
    this.searchForm.patchValue({
      noOfMale: values.noOfMale ? values.noOfMale.toString() : '',
      noOfFemale: values.noOfFemale ? values.noOfFemale.toString() : '',
      noOfChildren: values.noOfChildren ? values.noOfChildren.toString() : '',
      noOfRooms: values.noOfRooms ? values.noOfRooms.toString() : '',
    });
  }

  setGuestDisplayValue() {
    const data = this.searchForm.value;
    let roomCount = 0;
    let guestCount = 0;
    if (data.noOfChildren || data.noOfFemale || data.noOfMale || data.noOfRooms) {

      const childrenCount = data.noOfChildren ? Number(data.noOfChildren) : 0;
      const femaleCount = data.noOfFemale ? Number(data.noOfFemale) : 0;
      const maleCount = data.noOfMale ? Number(data.noOfMale) : 0;

      guestCount = childrenCount + femaleCount + maleCount;
      roomCount = data.noOfRooms ? Number(data.noOfRooms) : 0;
      return `${guestCount} guests | ${roomCount} rooms`
    } else {
      return null;
    }
  }

  clicks() {
    this.guestData.next({
      packOfHours: '0',
      noOfMale: '0',
      noOfFemale: '0',
      noOfChildren: '2',
      noOfRooms: '1',
    });
  }

  preventClose(event: MouseEvent) {
    event.stopImmediatePropagation();
  }

  onSearch() {
    if (this.searchForm.value.pickUpLocation !== this.menuPickUpLocationCoordinates.location) {
      this.searchForm.patchValue({
        pickUpCoordinates: ''
      })
    }
    this.updateQueryParams(this.searchForm.value);
  }
  onSearchFoodAndService() {
    if (this.searchForServiceAndFoodForm.value.pickUpLocation !== this.menuPickUpLocationCoordinates.location) {
      this.searchForServiceAndFoodForm.patchValue({
        pickUpCoordinates: ''
      })
    }
    this.updateQueryParams(this.searchForServiceAndFoodForm.value);
  }

}
