import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-coming-soon',
  templateUrl: './coming-soon.component.html',
  styleUrls: ['./coming-soon.component.scss']
})
export class ComingSoonComponent implements OnInit, OnDestroy {
  @ViewChild('modal') modal: any;
  @Input() onOpenModal!: Subject<any>;
  modalRef!: BsModalRef;
  value: any;
  eventsSubscription: any;
  constructor(private modalService: BsModalService) { }

  ngOnInit(): void {
    this.eventSubscription();
  }

  eventSubscription() {
    this.eventsSubscription = this.onOpenModal.subscribe(res => {
      this.value = res;
      this.modalRef = this.modalService.show(this.modal);
    });
  }

  onClickCancel(): void {
    this.modalRef.hide();
  }
  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }
}
