import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { Environment } from 'src/environments/environment';
import { RestService } from '../core/rest.service';
import { IAvailabilityPrice } from '../interfaces/availability-price.interface';
import { IPaginationResponse } from '../interfaces/rest-page-response.interface';
import { IRestResponse } from '../interfaces/rest-response.interface';

@Injectable({
  providedIn: 'root'
})
export class ExperienceService {

  constructor(private restService: RestService) { }

  public searchExperience(data:any): Observable<IRestResponse<IPaginationResponse<Array<any>>>> {
    return this.restService
      .post(Environment.UrlPath + `/experience/home`, data).pipe(
        map((res:any) => res)
        , catchError((error) =>
          throwError(error))
      );
  }

  public getExperience(id: string): Observable<IRestResponse<any>> {
    return this.restService.get(Environment.UrlPath +
      `/experience/getById/${id}`, null, false)
      .pipe(
        map((res:any) => res),
        catchError(error => throwError(error))
      );
  }

  public getPriceForProperty(fromDate: string, toDate: string): Observable<IRestResponse<Array<IAvailabilityPrice>>> {
    return this.restService.get(Environment.UrlPath +
      `/experience/getlisting-calender`, { fromDate, toDate }, false)
      .pipe(
        map((res:any) => {
          return res;
        }),
        catchError(error => throwError(error))
      );
  }

  public getExperienceList(): Observable<IRestResponse<any>> {
    return this.restService.get(Environment.UrlPath +
      `/experience/type-and-price`, null, false)
      .pipe(
        map((res:any) => res),
        catchError(error => throwError(error))
      );
  }
}
