import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { RestService } from '../core/rest.service';
import { map, catchError } from 'rxjs/operators';
import { IRestResponse } from '../interfaces/rest-response.interface';
import { Environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(private restService: RestService) { }
  public login(data:any): Observable<{}> {
    return this.restService
      .oauthLogin(Environment.baseUrl + `/oauth/token`, data, false).pipe(
        map((res) => res)
        , catchError((error) =>
          throwError(error))
      );
  }
  public logout(): Observable<{}> {
    return this.restService
      .oauthLogout(Environment.baseUrl + `/oauth/logout`, null, true).pipe(
        map((res) => res)
        , catchError((error) =>
          throwError(error))
      );
  }
  getForgotPasswordEmailLink(data:any) {
    return this.restService
      .post(
        Environment.baseUrl + `/adresnow/user/forgotpassword`, data, false
      )
      .pipe(map((res: any) => res));
  }
  onSettingPassword(data:any, email:string): Observable<any> {
    return this.restService.
      put(Environment.UrlPath + '/user/forgotPassword', data, false).pipe(
        map((res) => res)
        , catchError((error) =>
          throwError(error))
      );
  }
  onResendOtp(emailId:string): Observable<any> {
    return this.restService.
      get(Environment.UrlPath + '/user/otp/resend/' + emailId, null).pipe(
        map((res) => res)
        , catchError((error) =>
          throwError(error))
      );
  }
  public getForgotPasswordEmail(emailAddress:any): Observable<IRestResponse<{ token: string }>> {
    return this.restService
      .get(Environment.UrlPath + '/user/forgotPassword/' + emailAddress, null).pipe(
        map((res: any) => res)
        , catchError((error) =>
          throwError(error))
      );
  }
  public otpVerification(forgotPasswordRequest: any): Observable<IRestResponse<{ token: string }>> {
    return this.restService
      .put(Environment.UrlPath + '/user/forgotPassword/otpVerification', forgotPasswordRequest).pipe(
        map((res: any) => res)
        , catchError((error) =>
          throwError(error))
      );
  }
  public setForgotPassword(setPasswordDetails: any):Observable<IRestResponse<{ token: string }>> {
    return this.restService
      .put(Environment.UrlPath + '/user/forgotPassword', setPasswordDetails).pipe(
        map((res: any) => res)
        , catchError((error) =>
          throwError(error))
      );
  }
}
