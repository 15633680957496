import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-property-place-card',
  templateUrl: './property-place-card.component.html',
  styleUrls: ['./property-place-card.component.scss']
})
export class PropertyPlaceCardComponent implements OnInit {

  @Input() cardsDetails: any;
  @Input() priceFor: string='';
  @Input() perUnit: number=0;

  constructor() { }
  
  ngOnInit(): void {

  }

}
