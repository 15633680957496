import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { RestService } from '../core/rest.service';
import { IBookingProperty } from '../interfaces/booking-property.interface';
import { IPaginationResponse } from '../interfaces/rest-page-response.interface';
import { IRestResponse } from '../interfaces/rest-response.interface';
import { Environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BookingService {

  constructor(private restService: RestService) { }

  public addBooking(data:any): Observable<IRestResponse<any>> {
    return this.restService
      .post(Environment.UrlPath + `/booking`, data).pipe(
        map((res:any) => res)
        , catchError((error) =>
          throwError(error))
      );
  }

  initiatePayment(bookingId: string): Observable<IRestResponse<any>> {
    return this.restService
      .post(Environment.UrlPath + `/booking/${bookingId}/initiatePayment`, null).pipe(
        map((res: any) => res)
        , catchError((error) =>
          throwError(error))
      );
  }

  initiateCCAvenuePayment(params: any): Observable<IRestResponse<any>> {
    return this.restService
      .get(Environment.UrlPath + `/ccaveneu/paymenturl`, params).pipe(
        map((res: any) => res)
        , catchError((error) =>
          throwError(error))
      );
  }

  checkStatusOfBooking(bookingId: string, orderId: string): Observable<IRestResponse<any>> {
    return this.restService
      .get(Environment.UrlPath + `/ccaveneu/status/${bookingId}/${orderId}`, null).pipe(
        map((res: any) => res)
        , catchError((error) =>
          throwError(error))
      );
  }

  confirmPayment(bookingId: string, paymentDetails: any): Observable<IRestResponse<any>> {
    return this.restService
      .put(Environment.UrlPath + `/booking/${bookingId}/confirm`, paymentDetails).pipe(
        map((res: any) => res)
        , catchError((error) =>
          throwError(error))
      );
  }
  getBookingList(requestData: { pageNumber: number, pageSize: number, bookingType: string }): Observable<IRestResponse<IPaginationResponse<IBookingProperty[]>>> {
    return this.restService
      .get(Environment.UrlPath + `/booking/bookingOrderSummery`, requestData).pipe(
        map((res:any) => res)
        , catchError((error) =>
          throwError(error))
      );
  }
}
