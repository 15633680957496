<div class="d-block d-md-none mobile-screen">
  <div class="container">
    <div class="text-center align-items-center py-3 row">
      <div class="col-12 col-sm-7 col-md-6">
        <img
          src="../../assets/img/bml-logo.png"
          alt="Logo"
          class="logo-mobile"
        />
        <h1 class="pt-3">
          <span class="title-1">Download Your</span> <br /><span
            class="title-2"
          >
            BookMyLoft
          </span>
          <span class="title-3">Mobile App Here</span>
        </h1>
        <div class="row pt-4">
          <div class="col-6">
            <div (click)="onNavigateToAppDownload('playstore')">
              <img
                src="../../assets/img/play-store.png"
                alt="Playstore"
                class="store-img"
              />
            </div>
          </div>
          <div class="col-6">
            <div (click)="onNavigateToAppDownload('ios')">
              <img
                src="../../assets/img/app-store.png"
                alt="Appstore"
                class="store-img"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 col-sm-5 col-md-6">
        <img
          alt="Mobile"
          src="../../assets/img/bml-mobile.png"
          class="w-100 my-3"
        />
      </div>
    </div>
  </div>
</div>
