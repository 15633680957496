import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { MenuComponent } from './menu/menu.component';
import { FooterComponent } from './footer/footer.component';
import { SearchComponent } from './search/search.component';
import { SharedModule } from '../shared/shared.module';
import { CommonComponentModule } from '../common-component/common-component.module';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { PipesModule } from '../pipes/pipe.module';

@NgModule({
  declarations: [MenuComponent, FooterComponent, SearchComponent],
  imports: [
    CommonModule,
    SharedModule,
    MatAutocompleteModule,
    CommonComponentModule,
    FormsModule,
    ReactiveFormsModule,
    InfiniteScrollModule,
    PipesModule
  ],
  exports: [MenuComponent, FooterComponent]
})
export class MenuModule { }
