<div class="calendar-time-picker">
    <form [formGroup]="timePickerForm">
        <div *ngIf="selectedProperties && selectedProperties.packOfHours" class="">
            <select formControlName="packOfHours">
        <option value="" disabled>Select Pack of hours</option>
        <option *ngFor="let detail of packOfHours" [value]="detail.id">{{detail.value}}</option>
      </select>
        </div>
        <div class="row">
            <div *ngIf="selectedProperties && selectedProperties.checkInTime" class="col-6">
                <div class="ml-4 ps-3">
                    <label *ngIf="showLabel">Select check In Time</label>
                    <timepicker formControlName="checkInTime" [hourStep]="hourStep" [minuteStep]="minStep">
                    </timepicker>
                </div>
            </div>
            <div *ngIf="selectedProperties && selectedProperties.checkOutTime && conf.showItems===2" class="col-6">
                <div class="ps-2">
                    <label *ngIf="showLabel">Select check Out Time</label>
                    <timepicker formControlName="checkOutTime" [hourStep]="hourStep" [minuteStep]="minStep">
                    </timepicker>
                </div>
            </div>
        </div>
    </form>
</div>