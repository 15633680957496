import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { sha256 } from 'js-sha256';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { IRestResponse } from 'src/app/interfaces/rest-response.interface';
import { UserService } from 'src/app/services';
import { validateAllFormFields } from 'src/app/utils/validateform';
import { CustomValidators } from 'src/app/validators/custom.validator';

@Component({
  selector: 'app-register-modal',
  templateUrl: './register-modal.component.html',
  styleUrls: ['./register-modal.component.scss']
})
export class RegisterModalComponent implements OnInit {

  @ViewChild('modal') modal: any;
  @Input() onOpenModal!: Subject<any>;
  modalRef!: BsModalRef;
  eventsSubscription: any;
  registerForm!: FormGroup;
  openLogin: Subject<void> = new Subject<void>();
  openVerifyOtp: Subject<void> = new Subject<void>();
  registeredMobileNo: string='';
  editMobile = false;

  constructor(
    private formBuilder: FormBuilder,
    private modalService: BsModalService,
    private userService: UserService
  ) { }

  ngOnInit(): void {
    this.openModalSubscription();
  }

  openModalSubscription() {
    this.eventsSubscription = this.onOpenModal.subscribe((res: string) => {
      this.registeredMobileNo = res;
      this.initRegisterForm();
      this.modalRef = this.modalService.show(this.modal);
    });
  }

  initRegisterForm() {
    this.registerForm = this.formBuilder.group({
      firstName:['', [
        Validators.compose([
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(40),
        ])
      ]],
      emailId:['',[Validators.compose([Validators.required,CustomValidators.email])]],
      mobileNumber:[this.registeredMobileNo, [
          Validators.required,
          Validators.minLength(3),
          Validators.maxLength(18),
          Validators.pattern('^[0-9\+\-\]+$')
        ]
      ],
      password:['', [
        Validators.compose([
          Validators.required,
          Validators.minLength(5),

        ])
      ]],
    });
  }

  onClickSubmit() {
    if (!this.registerForm.valid) {
      validateAllFormFields(this.registerForm);
      return;
    }

    const data = Object.assign({}, this.registerForm.value);

    data.password = sha256(data.password);
    data.confirmPassword = data.password;


    this.userService.userSignUp(data).subscribe((res: IRestResponse<any>) => {
      if (res.status.code === 200) {
        this.modalRef.hide();
      }
    });
  }

  onClickLogin() {
    this.modalRef.hide();
    this.openLogin.next();
  }
}
