import { Injectable } from '@angular/core';
import { Observable, Subject, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { RestService } from '../core/rest.service';
import { IBooking } from '../interfaces/book-food.interface';
import { IPaginationResponse } from '../interfaces/rest-page-response.interface';
import { IRestResponse } from '../interfaces/rest-response.interface';
import { IService, IServiceById  } from '../interfaces/service.interface';
import { Environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BookServiceService {
  pickUpLocationCoordinates = new Subject<any>();
  dropOffLocationCoordinates = new Subject<any>();
  constructor(private restService: RestService) { }

  pickUpLocationCoordinatesSubscription(): Observable<{ latitude: number, longitude: number, location: string }> {
    return this.pickUpLocationCoordinates.asObservable();
  }
  dropOffLocationCoordinatesSubscription(): Observable<{ latitude: number, longitude: number, location: string }> {
    return this.pickUpLocationCoordinates.asObservable();
  }
  public getAllServiceDetails(data:any): Observable<IRestResponse<IPaginationResponse<Array<IService>>>> {
    return this.restService
      .post(Environment.UrlPath + `/service/home`, data).pipe(
        map((res:any) => res)
        , catchError((error) =>
          throwError(error))
      );
  }

  public getServiceCategory(): Observable<any> {
    return this.restService
      .get(Environment.UrlPath + `/master/hostType/SERVICE_PROVIDER`, null).pipe(
        map((res: any) => res)
        , catchError((error) =>
          throwError(error))
      );
  }

  public getServiceDetailsById(serviceListingId: string): Observable<IRestResponse<IServiceById>> {
    return this.restService
      .get(Environment.UrlPath + `/service/${serviceListingId}`, null).pipe(
        map((res: any) => res)
        , catchError((error) =>
          throwError(error))
      );
  }
  public updateBookingService(data:any, bookingDetailId: string): Observable<IRestResponse<IBooking>> {
    return this.restService
      .put(Environment.UrlPath + `/booking/${bookingDetailId}`, data).pipe(
        map((res:any) => res)
        , catchError((error) =>
          throwError(error))
      );
  }
}
