import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { IProperty } from 'src/app/interfaces/property.interface';


@Component({
  selector: 'app-property-detail-card',
  templateUrl: './property-detail-card.component.html',
  styleUrls: ['./property-detail-card.component.scss']
})
export class PropertyDetailCardComponent implements OnInit {

  @Input() cardsDetails: IProperty[] = [];
  @Input() title: string='';
  @Input() description: string='';
  @Input() priceFor: string='';
  @Input() perUnit: number=0;
  @Output() listingId = new EventEmitter();
  itemsPerSlide = 4;
  singleSlideOffset = true;
  noWrap = true;
  showIndicators = false;

  max = 5;
  rate = 2.5;
  isReadonly = true;
  constructor() {
  }

  ngOnInit(): void {

  }
  onClickProperty(property: any): void {
    const id = property.experienceListingId ? property.experienceListingId : property.propertyListingId;
    this.listingId.next({ listingId: id })
  }

}
