<nav
  class="
    navbar navbar-expand-lg navbar-transparent
    position-fixed
    navPosition
    pt-0
  "
  [ngClass]="
    showTransparentMenu && !extraHeight ? 'bg-transparent' : 'menuBlueBG'
  "
  id="headerMenu"
>
  <div class="container-fluid">
    <!-- Yet to implement -->
    <!-- <div class="btn-group" dropdown>
      <button id="menu" dropdownToggle type="button"
        class="btn btn-primary dropdown-toggle-1 bg-transparent border-0 p-0 mt-4 me-4" aria-controls="dropdown-menu"
        (click)="onClickMenu('clicked')">
        <img alt="MENU" src="../../../assets/img/menu.svg" />
        <div class="fw-boldfont-12 lineHeight1">Menu</div>
      </button>
      <ul id="dropdown-menu" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="menu">
        <li role="menuitem"><a class="dropdown-item montserratSB font-13" href="#">Menu item 1</a></li>
        <li role="menuitem"><a class="dropdown-item montserratSB font-13" href="#">Menu item 2</a></li>
        <li role="menuitem"><a class="dropdown-item montserratSB font-13" href="#">Menu item 3</a></li>
        <li role="menuitem"><a class="dropdown-item montserratSB font-13" href="#">Menu item 4</a></li>
      </ul>
    </div> -->
    <a class="navbar-brand mr-0" routerLink="/home">
      <img alt="Logo" src="../../../assets/img/logo.png" />
    </a>
    <a class="nav-link text-white me-lg-5 pt-2 ps-2" href="#">
      <div class="fw-boldmontserratB font-24 logoText">Book My Loft</div>
      <div class="montserratL font-14 mt-2 logoSubText lineHeight1">
        everyone can travel
      </div>
    </a>
    <a *ngIf="showSearch" class="nav-link mx-4 mx-xxl-5">
      <!-- Need to do styling -->
      <app-search [showFoodServiceMenu]="showFoodServiceMenu"></app-search>
    </a>
    <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
      <ul class="navbar-nav ms-auto">
        <li class="nav-item"></li>
        <li class="nav-item">
          <div class="btn-group" dropdown>
            <button
              id="help"
              dropdownToggle
              type="button"
              class="
                text-white
                dropdown-toggle
                bg-transparent
                border-0
                p-0
                mt-2
                me-4
                font-13
                montserratR
                lineHeight1
              "
              aria-controls="dropdown-help"
            >
              Help
            </button>
            <!-- Yet to implement -->
            <ul
              id="dropdown-help"
              *dropdownMenu
              class="dropdown-menu"
              role="menu"
              aria-labelledby="help"
            >
              <li role="menuitem">
                <a
                  class="dropdown-item montserratSB font-13"
                  href="mailto:info@bml.com"
                  target="_blank"
                >
                  <img
                    style="width: 15px"
                    src="../../../assets/img/mail.png"
                    alt="mail"
                  /><span class="ps-2">info@bml.com</span>
                </a>
              </li>

              <li role="menuitem">
                <a
                  href="https://wa.me/9833946853"
                  class="dropdown-item montserratSB font-13"
                  target="_blank"
                >
                  <img
                    style="width: 15px"
                    src="../../../assets/img/whatsapp.png"
                    alt="whatsapp"
                  /><span class="ps-2">WhatsApp</span>
                </a>
              </li>

              <li role="menuitem">
                <a
                  class="dropdown-item montserratSB font-13"
                  href="tel:9833946853"
                  target="_blank"
                >
                  <img
                    style="width: 15px"
                    src="../../../assets/img/telephone-call.png"
                    alt="Phone"
                  /><span class="ps-2">9833946853</span>
                </a>
              </li>
            </ul>
          </div>
        </li>

        <!-- <li class="nav-item">
          <div class="btn-group" dropdown>
            <button id="currency" dropdownToggle type="button"
              class="text-white dropdown-toggle bg-transparent border-0 p-0 mt-2 me-4 font-13 montserratR lineHeight1"
              aria-controls="currency-dropdown">
              INR &#8377;
            </button>

            <ul id="currency-dropdown" *dropdownMenu class="dropdown-menu" role="menu" aria-labelledby="currency">
              <li role="menuitem" *ngFor="let currency of currencies">
                <a class="dropdown-item montserratSB font-13" href="#">{{currency.currency}}</a>
              </li>
            </ul>
          </div>
        </li> -->

        <li class="nav-item">
          <div class="btn-group" dropdown [autoClose]="autoClose">
            <button
              id="main-menu"
              dropdownToggle
              type="button"
              (click)="onClickMenu()"
              class="
                text-white
                dropdown-toggle
                p-0
                me-4
                font-13
                montserratR
                lineHeight1
                mainMenuIco
              "
              aria-controls="main-menu-dropdown"
            >
              <img
                alt="Menu"
                src="../../../assets/img/main-menu.svg"
                class="float-left mainMenuImg1 me-1"
              />
              <img
                alt="Menu"
                src="../../../assets/img/user.svg"
                class="float-right mainMenuImg2 ms-3"
              />
            </button>
            <!-- Yet to implement -->
            <ul
              id="main-menu-dropdown"
              *dropdownMenu
              [ngClass]="onNotification ? 'notification-dropdown' : ''"
              class="dropdown-menu"
              role="menu"
              aria-labelledby="main-menu"
            >
              <div *ngIf="!dataService.getUserId && !onNotification" class="">
                <li role="menuitem">
                  <a
                    class="dropdown-item montserratSB font-13 pointer"
                    (click)="onClickLogin()"
                  >
                    {{ "menu.settings.login" | translate }}</a
                  >
                </li>
                <li role="menuitem">
                  <a
                    class="dropdown-item montserratSB font-13 pointer"
                    (click)="onClickCreateUser()"
                  >
                    {{ "menu.settings.signUp" | translate }}
                  </a>
                </li>
              </div>
              <div *ngIf="dataService.getUserId && !onNotification" class="">
                <li role="menuitem" (click)="onClickNotification()">
                  <a class="dropdown-item montserratSB font-13 pointer">
                    Notifications
                    <sup *ngIf="notificationCount !== 0"
                      ><span class="new-notification">{{
                        notificationCount
                      }}</span></sup
                    ></a
                  >
                </li>
                <li class="divider dropdown-divider"></li>
                <li role="menuitem">
                  <a
                    class="dropdown-item montserratSB font-13 pointer"
                    (click)="onMyBooking()"
                    >My Booking</a
                  >
                </li>

                <li role="menuitem">
                  <a class="dropdown-item montserratSB font-13 pointer"
                    >Account</a
                  >
                </li>
                <li class="divider dropdown-divider"></li>
                <li role="menuitem">
                  <a class="dropdown-item montserratSB font-13 pointer">Help</a>
                </li>
                <li role="menuitem">
                  <a
                    class="dropdown-item montserratSB font-13 pointer"
                    (click)="onClickLogout()"
                    >Log out</a
                  >
                </li>
              </div>
              <div
                *ngIf="onNotification"
                class=""
                (mouseleave)="onLeaveNotification()"
              >
                <li role="menuitem" *ngIf="notificationCount > 0">
                  <div
                    infiniteScroll
                    [scrollWindow]="false"
                    [infiniteScrollDistance]="2"
                    [infiniteScrollThrottle]="50"
                    (scrolled)="onScrollNotification()"
                    class="px-2"
                  >
                    <div
                      *ngFor="
                        let notification of allNotificationData;
                        let last = last
                      "
                      class="p-1"
                    >
                      <div
                        [ngClass]="
                          notification.status !== 'NEW'
                            ? 'text-dark'
                            : 'navbarGreenFont'
                        "
                        class="font-12 regular pb-1"
                      >
                        <span
                          >{{ notification.title }} -
                          {{
                            notification.additionalDetails.createdOn
                              | dateAndTime
                          }}</span
                        >
                      </div>
                      <div class="notification-dropdown-message regular pb-1">
                        {{ notification.message }}
                      </div>
                      <li
                        *ngIf="!last"
                        class="divider dropdown-divider my-1"
                      ></li>
                    </div>
                  </div>
                </li>
                <li role="menuitem" *ngIf="notificationCount === 0">
                  <div class="text-center">No New Notification</div>
                </li>
              </div>
            </ul>
          </div>
        </li>
      </ul>
    </div>
  </div>
</nav>
<h1 *ngIf="extraHeight" style="height: 100px"></h1>
<app-coming-soon [onOpenModal]="openModal"></app-coming-soon>

<ng-template #template>
  <div class="modal-body">
    <div class="orderSummary">
      <header class="text-white font-16 montserratSB px-4 py-3 text-center">
        Account
      </header>
      <div class="px-4 pt-3">
        <div class="font-16 text-black">
          <div class="row">
            <div
              class="
                col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12
                font-14
                text-center
                montserratR
              "
            >
              To place your order now.<br />
              log in to your existing account or sign up
            </div>
          </div>
        </div>
      </div>
      <section class="px-4 py-3">
        <div class="montserratSB font-16 text-black">
          <div class="row pb-2">
            <div
              class="
                col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12
                font-12
                text-center
              "
            >
              Enter login details or
              <a (click)="registerModal(register)" class="red-text pointer"
                >create an account</a
              >
            </div>
            <div
              class="
                col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12
                font-12
                text-center
                mt-2
              "
            >
              <mat-form-field class="example-full-width w-100">
                <input
                  matInput
                  placeholder="Phone number"
                  value=""
                  class="w-100 px-3 customInput montserratR customBorder"
                />
              </mat-form-field>
            </div>
            <div
              class="
                col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12
                font-12
                text-center
                mt-2
              "
            >
              <button
                class="
                  menuRedBG
                  text-white
                  font-16
                  montserratR
                  w-100
                  border-0
                  customBorder
                  mt-2
                  py-2
                "
              >
                Login
              </button>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</ng-template>

<ng-template #register>
  <div class="modal-body">
    <div class="orderSummary">
      <header class="text-white font-16 montserratSB px-4 py-3 text-center">
        Account
      </header>
      <div class="px-4 pt-3">
        <div class="font-16 text-black">
          <div class="row">
            <div
              class="
                col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12
                font-14
                text-center
                montserratR
              "
            >
              To place your order now.<br />
              log in to your existing account or sign up
            </div>
          </div>
        </div>
      </div>
      <section class="px-4 py-3">
        <div class="montserratSB font-16 text-black">
          <div class="row pb-2">
            <div
              class="
                col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12
                font-12
                text-center
              "
            >
              Enter login details or
              <a (click)="openLoginModal(template)" class="red-text pointer"
                >login to your account</a
              >
            </div>
            <div
              class="
                col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12
                font-12
                text-center
                mt-2
              "
            >
              <mat-form-field class="example-full-width w-100">
                <input
                  matInput
                  placeholder="Phone number"
                  value=""
                  class="w-100 px-3 customInput montserratR customBorder"
                />
              </mat-form-field>
            </div>
            <div
              class="
                col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12
                font-12
                text-center
                mt-2
              "
            >
              <mat-form-field class="example-full-width w-100">
                <input
                  matInput
                  placeholder="Name"
                  value=""
                  class="w-100 px-3 customInput montserratR customBorder"
                />
              </mat-form-field>
            </div>
            <div
              class="
                col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12
                font-12
                text-center
                mt-2
              "
            >
              <mat-form-field class="example-full-width w-100">
                <input
                  matInput
                  placeholder="Email"
                  value=""
                  class="w-100 px-3 customInput montserratR customBorder"
                />
              </mat-form-field>
            </div>
            <div
              class="
                col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12
                font-12
                text-center
                mt-2
              "
            >
              <mat-form-field class="example-full-width w-100">
                <input
                  matInput
                  placeholder="Password"
                  value=""
                  class="w-100 px-3 customInput montserratR customBorder"
                />
              </mat-form-field>
            </div>
            <div
              class="
                col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12
                font-12
                text-center
                mt-2
              "
            >
              <button
                class="
                  menuRedBG
                  text-white
                  font-16
                  montserratR
                  w-100
                  border-0
                  customBorder
                  mt-2
                  py-2
                "
              >
                Sign Up
              </button>
              <p class="montserratR font-12 text-center pt-2">
                By creating account. I accept
                <a class="pointer">terms and conditions</a>
              </p>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</ng-template>
<app-login-modal [onOpenModal]="openLogin"></app-login-modal>
<app-register-modal [onOpenModal]="openRegister"></app-register-modal>
