import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { BookFoodService } from 'src/app/services/book-food.servive';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss']
})
export class PaginationComponent implements OnInit {
  totalItems!: number;
  pageSize = 20;
  maxSize = 5;
  pageNumber = 1;
  constructor(private route: ActivatedRoute,
    private router: Router, private bookFoodService: BookFoodService) {
    this.paramChange({ pageNumber: this.pageNumber });
  }

  ngOnInit(): void {
    this.getFoodCartPagination();
  }
  pageChanged(event: any): void {
    this.pageNumber = event.page;
    this.paramChange({ pageNumber: this.pageNumber });
  }
  getFoodCartPagination(): void {
    this.bookFoodService.foodCartPagination.subscribe((res) => {
      this.totalItems = res.totalElements
      this.pageSize = res.pageSize;
    });
  }
  paramChange(paramValue: any): void {
    const queryParams: Params = paramValue;
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams,
        queryParamsHandling: 'merge'
      });
  }
}
