<div class="loginBackground">
  <div class="container">
    <div class="row">
      <div class="col-xs-8 col-lg-8 col-md-6 col-sm-4 col-12"></div>
      <div class="col-xs-4 col-lg-4 col-md-6 col-sm-8 col-12">
        <div class="loginPad">
          <div class="text-center"><img alt="logo" src="../../assets/img/login-logo.png" class="loginLogo my-3"></div>
          <div class="orangeBG py-4 px-5 borderRdius10">
            <div class=" pt-1 pb-3 text-center fw-boldfont-18">Sign in</div>
            <div>
              <form [formGroup]="loginForm">
                <div class="text-info small"><sup class="font-red"></sup>
                  <div class="row">
                    <div class="col-md-12">
                      <div class="pt-1 pb-3 text-center fw-boldfont-18">Email</div>
                      <input class="form-control w-100 bg-white my-2" formControlName="email" type="text">
                      <div class="error "
                        *ngIf="loginForm.controls['email'].invalid && loginForm.controls['email'].dirty">
                        <span *ngIf="loginForm.controls['email'].errors?.['required']">Please enter your email-id</span>
                        <span
                          *ngIf="loginForm.controls['email'].errors?.['email'] && !loginForm.controls['email'].errors?.['maxlength'] && !loginForm.controls['email'].errors?.['required']">Please
                          enter your email address in format : Yourname@example.com</span>
                      </div>
                    </div>
                    <div class="col-md-12 my-2">
                      <div class="pt-1 pb-3 text-center fw-boldfont-18">Password</div>
                      <input pInputText maxlength="50" formControlName="password" type="password"
                        class="form-control w-100 bg-white" name="password">
                      <div class="error "
                        *ngIf="loginForm.controls['password'].invalid &&loginForm.controls['password'].dirty">
                        <span *ngIf="loginForm.controls['password'].errors?.['required']">Please enter the
                          password.<br></span>
                        <span
                          *ngIf="!loginForm.controls['password'].errors?.['required'] && loginForm.controls['password'].errors?.['minlength']">Password
                          needs to be atleast 5
                          characters long.<br></span>
                        <span
                          *ngIf="!loginForm.controls['password'].errors?.['required'] && loginForm.controls['password'].errors?.['maxlength']">Password
                          is too long.<br></span>
                        <span
                          *ngIf="!loginForm.controls['password'].errors?.['required'] && !loginForm.controls['password'].errors?.['minlength'] && !loginForm.controls['password'].errors?.['maxlength'] && loginForm.controls['password'].errors?.['oneDigitValidator']">It
                          should contain one number.<br></span>
                        <span
                          *ngIf="!loginForm.controls['password'].errors?.['required'] &&  !loginForm.controls['password'].errors?.['minlength'] && !loginForm.controls['password'].errors?.['maxlength'] &&loginForm.controls['password'].errors?.['specialChar']">It
                          should contain one special character.<br></span>
                        <span
                          *ngIf="!loginForm.controls['password'].errors?.['required'] && !loginForm.controls['password'].errors?.['minlength'] && !loginForm.controls['password'].errors?.['maxlength'] &&loginForm.controls['password'].errors?.['uppercase']">It
                          should contain one uppercase character.<br></span>
                      </div>
                    </div>
                    <div class="text-center w-100 pb-4 pt-3">
                      <a (click)="onClickForgetPasswordModal(template)" class="font-12 light pointer">Forgot Password
                        ?</a>
                    </div>
                  </div>
                </div>
              </form>
              <div class="row">
                <div class="col-md-12 text-center">
                  <button class="border-0 px-4 py-1 borderRdius5 bg-black  fw-boldpointer" (click)="onClickLogin()">
                    Signin
                  </button>
                  <button type="button" (click)="onClickFbLogin()" mat-flat-button color="primary">Sign In With Facebook
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<!--Forgot Password Modal-->

<ng-template #template>
  <form [formGroup]="forgotPasswordForm">
    <div class="modal-header">
      <h4 class="modal-title pull-left medium">Forgot Password</h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="forgotPassword.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-md-12 font-20">
          <input class="form-control w-100 bg-white mt-3 font-14 light py-2" type="text" formControlName="emailAddress"
            placeholder="Enter your email ID">
          <div class=""
            *ngIf="forgotPasswordForm.controls['emailAddress'].invalid && forgotPasswordForm.controls['emailAddress'].dirty">
            <span class="error" *ngIf="forgotPasswordForm.controls['emailAddress'].errors?.['required']">Email address is
              required</span>
            <span class="error" *ngIf="forgotPasswordForm.controls['emailAddress'].errors?.['email'] &&
              !forgotPasswordForm.controls['emailAddress'].errors?.['required']
            ">Please enter your email address in format <br />
              for example: Yourname@example.com</span>
          </div>
        </div>
        <div class="col-md-12 text-center">
          <button class="border-0 px-4 py-1 borderRdius5 bg-black medium my-4 pointer"
            (click)="onClickResetPassword()">Reset my password</button>
        </div>
      </div>
    </div>
  </form>
</ng-template>