import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { RatingModule } from 'ngx-bootstrap/rating';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { TabsModule } from 'ngx-bootstrap/tabs';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { AgmCoreModule } from '@agm/core';
import { CommonModule } from '@angular/common';
import { AppRoutingModule } from './app-routing.module';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { MatInputModule } from '@angular/material/input';

import { AppComponent } from './app.component';
import { MessageComponent } from './core/message/message.component';
import { LoaderComponent } from './core/loader/loader.component';
import { SharedModule } from './shared/shared.module';
import { SharedServiceModule } from './services/sharedservice.module';
import { MenuModule } from './menu/menu.module';
import { HTTP_INTERCEPTORS, HttpClientModule, HttpClient } from '@angular/common/http';
import { ErrorInterceptor } from './interceptors/http.interceptor';
import { LoginComponent } from './login/login.component';
import { NoContentComponent } from './nocontent/nocontent.component';
import { BookEventsComponent } from './book-events/book-events.component';
import { ReactiveFormsModule } from '@angular/forms';
import { MatSelectModule } from '@angular/material/select';
// import { HomeDetailCardsComponent } from './home/home-detail-cards/home-detail-cards.component';
// import { HomePlacesCardsComponent } from './home/home-places-cards/home-places-cards.component';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { FoodOrderModule } from './food-order/food-order.module';
import { CommonComponentModule } from './common-component/common-component.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { SuccessComponent } from './success/success.component';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsAndConditionComponent } from './terms-and-condition/terms-and-condition.component';
import { LandingPageMobileComponent } from './landing-page-mobile/landing-page-mobile.component';
import { Environment } from 'src/environments/environment';



@NgModule({
  declarations: [
    AppComponent,
    // HomeComponent,
    LoginComponent,
    LoaderComponent,
    MessageComponent,
    NoContentComponent,
    BookEventsComponent,
    SuccessComponent,
    PrivacyPolicyComponent,
    TermsAndConditionComponent,
    LandingPageMobileComponent,
    // HomeDetailCardsComponent,
    // HomePlacesCardsComponent
  ],
  imports: [
    BrowserModule,
    CommonModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot(),
    SharedModule,
    SharedServiceModule.forRoot(),
    HttpClientModule,
    MenuModule,
    BsDropdownModule.forRoot(),
    ReactiveFormsModule,
    BrowserAnimationsModule,
    AgmCoreModule,
    BsDatepickerModule.forRoot(),
    PaginationModule.forRoot(),
    AgmCoreModule.forRoot({
      apiKey: Environment.googleMapApiKey,
      libraries: ['places']
    }),
    TimepickerModule.forRoot(),
    TabsModule.forRoot(),
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    FoodOrderModule,
    CarouselModule.forRoot(),
    RatingModule.forRoot(),
    ScrollToModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    CommonComponentModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: ErrorInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}
