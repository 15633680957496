import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-landing-page-mobile',
  templateUrl: './landing-page-mobile.component.html',
  styleUrls: ['./landing-page-mobile.component.scss']
})
export class LandingPageMobileComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

  onNavigateToAppDownload(device: string): void {
    if (device === 'ios') {
      window.open(
        'https://apps.apple.com/in/app/bookmyloft/id6449763332',
        '_blank'
      );
    } else {
      window.open(
        'https://play.google.com/store/apps/details?id=com.bookmyloft.app',
        '_blank'
      );
    }
  }

}
