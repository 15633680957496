import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { BookingService } from '../services/booking.service';

type titleArray = {
  type: string;
  mainTitle: string;
  subTitle: string;
  imagePath:string;
}
@Component({
  selector: 'app-success',
  templateUrl: './success.component.html',
  styleUrls: ['./success.component.scss']
})
export class SuccessComponent implements OnInit {
  bookingId: string = ''
  orderId: string = ''
  paymentStatus: string = ''
  titleArray: titleArray[] = [
    {
      type: 'Luxury Stays',
      mainTitle: 'Your room is successfully booked!',
      subTitle: 'Our team will contact you soon.',
      imagePath: '../../assets/img/success-room-booked.png'
    },
    {
      type: 'Budget Stays',
      mainTitle: 'Your room is successfully booked!',
      subTitle: 'Our team will contact you soon.',
      imagePath: '../../assets/img/success-room-booked.png'
    },
    {
      type: 'Hourly Stays',
      mainTitle: 'Your booking was successful!',
      subTitle: 'Our team will contact you soon.',
      imagePath: '../../assets/img/success-room-booked.png'
    },
    {
      type: 'Special Function',
      mainTitle: 'Your party venue was successfully booked!',
      subTitle: 'Our team will contact you soon.',
      imagePath: '../../assets/img/success-party.png'
    },
    {
      type: 'Day Picnic',
      mainTitle: 'Your booking was successful!',
      subTitle: 'Our team will contact you soon.',
      imagePath: '../../assets/img/success-booking.png'
    },
    {
      type: 'Experience Provider',
      mainTitle: 'Your booking was successful!',
      subTitle: 'Our team will contact you soon.',
      imagePath: '../../assets/img/success-booking-2.png'
    },
    {
      type: 'Foods & Beverages',
      mainTitle: 'Your order is placed successfully!',
      subTitle: 'Our delivery team will contact you soon.',
      imagePath: '../../assets/img/success-food-order.png'
    },
    {
      type: 'Service Provider',
      mainTitle: 'Your booking is placed successfully!',
      subTitle: 'Our delivery team will contact you soon.',
      imagePath: '../../assets/img/success-img.png'
    },
  ]
  listingType: string;
  listingSubType: string;
  selectedTitle!: titleArray;
  constructor(
    private route: ActivatedRoute,
    private bookingService: BookingService
  ) {
    this.listingType = this.route.snapshot.queryParamMap.get('type')||'';
    this.listingSubType = this.route.snapshot.queryParamMap.get('subType')||'';
    this.bookingId = this.route.snapshot.paramMap.get('bookingId') || '';
    this.orderId = this.route.snapshot.paramMap.get('orderId') || '';
  }

  ngOnInit(): void {
    this.getTitle();
    this.getStatus()
  }

  getTitle() {
    this.selectedTitle = this.titleArray.filter((item: titleArray) =>
      (item.type === this.listingType || item.type === this.listingSubType))[0];


  }
  getStatus(): void{
    debugger
    if(this.bookingId && this.orderId){
      this.bookingService.checkStatusOfBooking(this.bookingId, this.orderId).subscribe(res=>{
        if(res.response){
          this.paymentStatus =  res.response.paymentStatus
        }
      })
    }
  }
  onNavigateToAppDownload(device: string): void {
    if (device === 'ios') {
      window.open(
        'https://apps.apple.com/in/app/bookmyloft/id6449763332',
        '_blank'
      );
    } else {
      window.open(
        'https://play.google.com/store/apps/details?id=com.bookmyloft.app',
        '_blank'
      );
    }
  }
}
