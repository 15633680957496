import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { sha256 } from 'js-sha256';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { IRestResponse } from 'src/app/interfaces/rest-response.interface';
import { AuthService } from 'src/app/services';
import { validateAllFormFields } from 'src/app/utils/validateform';
import { CustomValidators } from 'src/app/validators/custom.validator';
import { Match } from 'src/app/validators/match.validator';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  @ViewChild('forgotPasswordModal') modal: any;
  @Input() onOpenModal!: Subject<any>;
  modalRef!: BsModalRef;
  eventsSubscription: any;
  forgotPasswordForm!: FormGroup;
  otpForm!: FormGroup;
  setPasswordForm!: FormGroup;
  token = '';
  toShowEmailId = true;
  toShowOtp = false;
  toShowSetPassword = false;
  constructor(private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private authService: AuthService) { }

  ngOnInit(): void {
    this.initForgotPasswordModal();
  }
  initForgotPasswordModal(): void {
    this.eventsSubscription = this.onOpenModal.subscribe((res) => {
      this.initLoginForm();
      this.toShowEmailId = true;
      this.toShowOtp = false;
      this.toShowSetPassword = false;
      this.modalRef = this.modalService.show(this.modal);
    });
  }
  initLoginForm() {
    this.forgotPasswordForm = this.formBuilder.group({
      emailId: new FormControl('', [
        Validators.required,
        CustomValidators.email()]),
    })
  }
  onClickForgotPassword(): void {
    if (!this.forgotPasswordForm.valid) {
      validateAllFormFields(this.forgotPasswordForm);
      return;
    }

    this.authService.getForgotPasswordEmail(this.forgotPasswordForm.value.emailId).subscribe((res: IRestResponse<{ token: string }>) => {
      this.token = res.response.token;
      this.toShowEmailId = false;
      this.toShowOtp = true;
      this.toShowSetPassword = false;
      this.initOtpForm();
    });
  }
  initOtpForm(): void {
    this.otpForm = this.formBuilder.group({
      token: [this.token],
      otp: ['', [Validators.required]]
    },
    )
  }
  onClickOtpPassword(): void {
    if (!this.otpForm.valid) {
      validateAllFormFields(this.otpForm);
      return;
    }
    this.authService.otpVerification(this.otpForm.value).subscribe((res) => {
      this.token = res.response.token;
      this.initSetPasswordForm();
      this.toShowEmailId = false;
      this.toShowOtp = false;
      this.toShowSetPassword = true;
    })
  }
  initSetPasswordForm(): void {
    this.setPasswordForm = this.formBuilder.group({
      token: [this.token],
      password: ['', [Validators.required]],
      confirmPassword: ['', [Validators.required]],
    },
      { validators: Match('password', 'confirmPassword') }
    )
  }
  onClickSetForgotPassword(): void {
    if (!this.setPasswordForm.valid) {
      validateAllFormFields(this.setPasswordForm);
      return;
    }
    const request = this.setPasswordForm.value;
    request.password = sha256(request.password);
    request.confirmPassword = sha256(request.confirmPassword);
    this.authService.setForgotPassword(request).subscribe((res) => {
      this.modalRef.hide();
    }, (error) => {
      if (error) {
        request.password = this.setPasswordForm.value.get('password').value;
        request.confirmPassword = this.setPasswordForm.value.get('confirmPassword').value;
      }
    })
  }
  
}
