import { NgModule, ModuleWithProviders } from '@angular/core';
import { LoaderService } from '../core/loader/loader.service';
import { MessageService } from '../core/message/message.service';
import { AuthService } from './auth.service';
import { UserService } from './user.service';
import { DataService } from './data.service';
import { RestService } from '../core/rest.service';
import { BookServiceService } from './book-service.service';
import { BookFoodService } from './book-food.servive';

// to share single instance of services across all modules and components
@NgModule({})
export class SharedServiceModule {
  static forRoot(): ModuleWithProviders<SharedServiceModule> {
    return {
      ngModule: SharedServiceModule,
      providers: [RestService, LoaderService, MessageService, AuthService,
        UserService, DataService, BookServiceService, BookFoodService]
    };
  }
}
