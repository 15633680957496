import { MapsAPILoader } from '@agm/core';
import { DatePipe } from '@angular/common';
import { Injectable, NgZone } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UtilityService {
  public locationSearch = new Subject<any>();
  public locationSearchForReview = new Subject<any>();
  constructor(private mapsAPILoader: MapsAPILoader,
    private ngZone: NgZone,) { }

    public isFieldInvalid(control: string, formGroup: FormGroup) {
      return (
        formGroup.get(control)?.invalid &&
        formGroup.get(control)?.touched
      );
    }

  public removeEmpty(obj:any) {
    return Object.assign(
      {},
      ...Object.keys(obj)
        .filter((el) => obj[el] !== undefined && obj[el] !== '' && obj[el] !== null)
        .map((el) => {
          return obj[el] instanceof Array && obj[el].length === 0 ? 0 : ({ [el]: obj[el] });
        })
    );
  }

  // function to check if the object value exists and return '' instead of the value. to reduce code
  public ifExists(obj:any) {
    return obj ? obj : '';
  }

  //Function to check the date a weekday or weekend
  public checkWeekEnd(date: Date): boolean {
    return !(date.getDay() % 6) ? true : false;
  }

  public setCurrentPosition(): { longitude: number, latitude: number, zoom: number, location: string } {
    const currentMapSettings: { longitude: number, latitude: number, zoom: number, location: string } =
      { latitude: 0, longitude: 0, zoom: 0, location: '' };
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        currentMapSettings.latitude = position.coords.latitude;
        currentMapSettings.longitude = position.coords.longitude;
        currentMapSettings.zoom = 13;
      });
    }
    return currentMapSettings;
  }

  public searchByLocation(locationSearchId: string): void {
    let searchByLocationMap: { longitude: number, latitude: number, zoom: number, location: any } =
      { latitude: 0, longitude: 0, zoom: 0, location: '' };
    const inputTag = document.getElementById(locationSearchId) as HTMLInputElement;
    searchByLocationMap = this.setCurrentPosition();
    this.mapsAPILoader.load().then(() => {
      const userLocation = new google.maps.places.Autocomplete(inputTag);
      userLocation.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const place: google.maps.places.PlaceResult = userLocation.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          searchByLocationMap.location = place.formatted_address;
          searchByLocationMap.latitude = place.geometry.location.lat();
          searchByLocationMap.longitude = place.geometry.location.lng();
          this.locationSearch.next(searchByLocationMap);
        });
      });
    });
  }
  public searchByLocationForReview(locationSearchId: string): void {
    let searchByLocationMap: { longitude: number, latitude: number, zoom: number, location: any } =
      { latitude: 0, longitude: 0, zoom: 0, location: '' };
    const inputTag = document.getElementById(locationSearchId) as HTMLInputElement;
    searchByLocationMap = this.setCurrentPosition();
    this.mapsAPILoader.load().then(() => {
      const userLocation = new google.maps.places.Autocomplete(inputTag);
      userLocation.addListener('place_changed', () => {
        this.ngZone.run(() => {
          const place: google.maps.places.PlaceResult = userLocation.getPlace();
          if (place.geometry === undefined || place.geometry === null) {
            return;
          }
          searchByLocationMap.location = place.formatted_address;
          searchByLocationMap.latitude = place.geometry.location.lat();
          searchByLocationMap.longitude = place.geometry.location.lng();
          this.locationSearchForReview.next(searchByLocationMap);
        });
      });
    });
  }
  public convertDate(date: Date, format: string): string | null {
    return date && format ? new DatePipe('en').transform(date, format) : null;
  }
  public checkValidAndDirty(control: AbstractControl | null | undefined) {

    return control && control.invalid && control.dirty ? true : false;
  }
}
