<ng-template #modal>
  <div class="p-5">
    <div class="orderSummary">
      <header class="modal-title text-white font-16 montserratSB px-4 py-3 text-center">Account</header>
      <!-- <button type="button" class="close pull-right" aria-label="Close" id="1" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button> -->

      <div class="text-center">
        <form [formGroup]="loginForm">
          <p class="font-14  montserratSB pt-3"> To place your booking now, <br>
            log into your existing account or sign up. </p>
          <p class="pt-3 pb-4 montserratSB font-12"> Enter login details or <span (click)="onClickCreateUser()"
              style="color:blue" class="pointer font-12 red-text">create an
              account</span> </p>

          <div class="row-12">
            <div class=" mb-3 px-3">

              <input class="w-100 light montserratR px-3 py-4 inputBoxAddUser font-14" formControlName="username"
                type="text" placeholder="Email">
              <div class="error red-text " *ngIf="loginForm.controls['username'].invalid && 
                        loginForm.controls['username'].dirty">
                <span *ngIf="loginForm.controls['username'].errors?.['required']">
                  Please enter your email-id</span>
                <span *ngIf="loginForm.controls['username'].errors?.['username'] && 
                            !loginForm.controls['username'].errors?.['maxlength'] &&
                            !loginForm.controls['username'].errors?.['required']">
                  Please enter your email address in format : Yourname@example.com</span>
              </div>
            </div>
          </div>
          <div class="row-12">
            <div class=" mb-3 px-3">

              <input pInputText maxlength="50" formControlName="password" type="password" placeholder="Password"
                class="w-100 light montserratR px-3 py-4 inputBoxAddUser font-14" name="password">
              <div class="error red-text"
                *ngIf="loginForm.controls['password'].invalid &&loginForm.controls['password'].dirty">
                <span *ngIf="loginForm.controls['password'].errors?.['required']">
                  Please enter the password.<br></span>
              </div>
            </div>
          </div>
          <div class="row-12">
            <div class=" mb-3 px-3">
              <span class="pointer" (click)="onClickForgotPassword()">Forgot Password?</span>
            </div>
          </div>
          <div class="text-center py-4 px-4">
            <button class="font-18 w-100 text-white menuRedBG montserratSB py-3 border-0 submitBtn  pointer" id="5"
              (click)="onClickLogin()">
              {{'commonForm.loginButton' | translate }}</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</ng-template>

<app-register-modal [onOpenModal]="openRegister"></app-register-modal>

<!-- <app-verify-otp-modal [onOpenModal]="openRegister"></app-verify-otp-modal> -->
<app-forgot-password [onOpenModal]="openForgotPassword"></app-forgot-password>