import { DataService } from './../../services/data.service';
import { Component, OnInit, Inject, TemplateRef, HostListener } from '@angular/core';
import { BsModalService, BsModalRef } from 'ngx-bootstrap/modal';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { DOCUMENT } from '@angular/common';
import { IRestResponse } from 'src/app/interfaces/rest-response.interface';
import { ICurrency } from 'src/app/interfaces/currency-master.interface';
import { Subject } from 'rxjs';

import { NotificationService } from 'src/app/services/notification.service';
import { INotification, INotificationCount } from 'src/app/interfaces/notification.interface';
import { IPaginationResponse } from 'src/app/interfaces/rest-page-response.interface';
import { BsDropdownConfig } from 'ngx-bootstrap/dropdown';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
  providers: [{ provide: BsDropdownConfig, useValue: { isAnimated: true, autoClose: true } }]
})
export class MenuComponent implements OnInit {

  classApplied = true;
  welcomeMessage: string='';
  showTransparentMenu = true;
  currencies: ICurrency[]=[];
  openModal = new Subject<void>();
  modalRef!: BsModalRef;
  showSearch = false;
  extraHeight = false;
  showFoodServiceMenu = false;
  openLogin: Subject<void> = new Subject<void>();
  openRegister: Subject<void> = new Subject<void>();
  allNotificationData: Array<INotification> = [];
  notificationCount = 0;
  pageNumber = 0;
  showNotificationList = false;
  autoClose = true;
  onNotification = false;
  constructor(
    private modalService: BsModalService,
    private router: Router,
    public auth: AuthService,
    @Inject(DOCUMENT) private document: Document,
    private notificationService: NotificationService,
    public dataService: DataService,) {
  }

  ngOnInit() {
    this.router.events.subscribe((val) => {
      this.showSearch = this.router.url.includes('booking-stay') ||
        (this.router.url.includes('book-services') ||
          this.router.url.includes('book-food')) ||
        (window.location.pathname.includes('success') === true) ||
        (window.location.pathname.includes('my-booking') === true) ? true : false;
      this.extraHeight = this.router.url.includes('home') ? false : true;
      this.showFoodServiceMenu = (this.router.url.includes('book-services')
        || this.router.url.includes('book-food')
        || (window.location.pathname.includes('success') === true) ||
        (window.location.pathname.includes('my-booking') === true)) ? true : false;
      this.showTransparentMenu = !this.showSearch;
    });
    this.getCurrencyList();
  }
  @HostListener('window:scroll', [])
  onWindowScroll() {
    this.showTransparentMenu = (document.body.scrollTop > 250 ||
      document.documentElement.scrollTop > 250 || this.showSearch) ? false : true;
  }
  onClickNotification() {
    this.setNotificationsAsRead();
    this.onNotification = true;
    this.autoClose = false;
  }
  onLeaveNotification(): void {
    this.onNotification = true;
    this.autoClose = true;
  }
  onScrollNotification() {
    this.pageNumber++;
    this.getAllNotification();
  }
  setNotificationsAsRead() {
    const notificationId = this.allNotificationData
      .filter((ele) => ele.status === "NEW")
      .map((ele) => ele.notificationId);
    this.notificationService.updateNotificationsAsRead(notificationId).subscribe((ele) => {
      this.pageNumber = 0;
    });
  }
  getAllNotification() {
    this.allNotificationData = [];
    this.notificationService.getNewNotification(this.pageNumber).subscribe((el: IRestResponse<IPaginationResponse<INotification[]>>) => {
      this.allNotificationData = el.response.content;
    });
  }
  onClickMenu(): void {
    this.onNotification = false;
    if (this.dataService.getUserId) {
      this.getNotificationCount();
      this.getAllNotification();
    }
  }
  getNotificationCount(): void {
    this.showNotificationList = false;
    if (this.dataService.getUserId) {
      this.notificationService.getNewNotificationCount().subscribe((res: IRestResponse<INotificationCount>) => {
        this.notificationCount = res.response.count
      })
    }
  }
  openLoginModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-custom' });
  }

  registerModal(register: TemplateRef<any>) {
    this.modalRef = this.modalService.show(register, { class: 'modal-custom' });
  }

  getCurrencyList() {
    this.dataService.getCurrencyMaster().subscribe((res: IRestResponse<Array<ICurrency>>) => {
      this.currencies = res.response;
    });
  }

  onClickHelp(i:any) {
    this.openModal.next(i);
  }

  onClickCurrency(i:any) {
    this.openModal.next(i);
  }


  onClickSettings(i:any) {
    this.openModal.next(i);
  }
  onClickLogin() {
    this.openLogin.next();
  }
  onClickLogout() {
    this.auth.logout().subscribe((res) => { });
    this.dataService.clearLoginData();
    this.router.navigate(['/home'])
  }
  onClickCreateUser() {
    this.openRegister.next();
  }
  onMyBooking(): void {
    this.router.navigate(['/my-booking'])
  }
}
