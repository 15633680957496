<div class="guest-room-box py-2 rounded-3">
  <form [formGroup]="guestRoomForm">
    <ul class="p-2 mb-0">
      <li *ngIf="selectedProperties.male">
        <div class="row pb-1">
          <div class="col-3 text-center">
            <img src="../../assets/img/male.png" alt="" class="roomimg">
          </div>
          <div class="col-4 p-0">
            <span class="montserratL font-12">Male</span>
            <p class="montserratL font-8 mb-0">Ages 13 or above</p>
          </div>
          <div class="col-5  countinput">
            <div class="d-flex align-items-center justify-content-center">
              <button (click)="onClickMinus('noOfMale')" class="outline-0 button-1 p-0">-</button>
              <input type="text" formControlName="noOfMale" readonly>
              <button (click)="onClickAdd('noOfMale')" class="outline-0 button-2 p-0">+</button>
            </div>
          </div>
        </div>
      </li>
      <li *ngIf="selectedProperties.female">
        <div class="row pb-1">
          <div class="col-3 text-center">
            <img src="../../assets/img/female.png" alt="" class="roomimg">
          </div>
          <div class="col-4 p-0">
            <span class="montserratL font-12">Female</span>
            <p class="montserratL font-8">Ages 13 or above</p>
          </div>
          <div class="col-5 countinput">
            <div class="d-flex align-items-center justify-content-center">
              <button (click)="onClickMinus('noOfFemale')" class="outline-0 button-1 p-0">-</button>
              <input type="text" formControlName="noOfFemale" value="1" readonly>
              <button (click)="onClickAdd('noOfFemale')" class="outline-0 button-2 p-0">+</button>
            </div>
          </div>
        </div>
      </li>
      <li *ngIf="selectedProperties.children">
        <div class="row pb-1">
          <div class="col-3 text-center">
            <img src="../../assets/img/children.png" alt="" class="roomimg">
          </div>
          <div class="col-4 p-0">
            <span class="montserratL font-12">Children</span>
            <p class="montserratL font-8">Ages 2 - 12</p>
          </div>
          <div class="col-5 countinput">
            <div class="d-flex align-items-center justify-content-center">
              <button (click)="onClickMinus('noOfChildren')" class="outline-0 button-1 p-0">-</button>
              <input type="text" formControlName="noOfChildren" class="" readonly>
              <button (click)="onClickAdd('noOfChildren') " class="outline-0 button-2 p-0">+</button>
            </div>

          </div>
        </div>
      </li>
      <li *ngIf="selectedProperties.room">
        <div class="row pb-1">
          <div class="col-3 text-center">
            <img src="../../assets/img/rooms.png" alt="" class="roomimg">
          </div>
          <div class="col-4 p-0">
            <span class="montserratL font-12">Rooms</span>
          </div>
          <div class="col-5 countinput">
            <div class="d-flex align-items-center justify-content-center">
              <button (click)="onClickMinus('noOfRooms')" class="outline-0 button-1 p-0">-</button>
              <input type="text" formControlName="noOfRooms" readonly>
              <button (click)="onClickAdd('noOfRooms')" class="outline-0 button-2 p-0">+</button>
            </div>

          </div>
        </div>
      </li>
    </ul>
  </form>
</div>