<div class="row">
  <div class="col-xl-9 col-lg-9 col-md-8 col-sm-7 col-9">
    <h5 class="font-24 montserratR pb-1 mb-0">
      {{ title }}
    </h5>
    <label class="text-dark montserratL font-16">
      {{description}}
    </label>
  </div>
  <div class="col-xl-3 col-lg-3 col-md-4 col-sm-5 col-3 nextPreviousBtn text-end my-2">
    <!-- <a>
      <img alt="previous" src="../../../assets/img/back-button.svg" class="ms-3nextpreviousDisabled" />
    </a>
    <a>
      <img alt="previous" src="../../../assets/img/fast-forward.svg" class="ms-3" />
    </a> -->
  </div>
</div>
<div *ngIf="cardsDetails.length === 0">
  <span>Coming Soon</span>
</div>
<div *ngIf="cardsDetails.length > 0">
  <carousel id="propertyCarousel" [itemsPerSlide]="itemsPerSlide" [singleSlideOffset]="singleSlideOffset"
    [showIndicators]="showIndicators" [noWrap]="noWrap">  <!-- [interval]="false" -->
    <slide *ngFor="let details of cardsDetails" (click)="onClickProperty(details)" class="pointer">
      <div class="position-relative" class="insideCarousel">
        <carousel [showIndicators]="showIndicators">
          <slide *ngFor="let image of details.images">
            <img [src]="image.location" class="image-fluid w-100 d-block max-height-260" alt="first slide">
          </slide>
        </carousel>
        <label id="topLabel" class="position-absolute text-white montserratSB font-14 px-2 py-1">
          {{ details.propertyType}}
        </label>
      </div>

      <div class="montserratB pt-2 pb-1 w-100">
        {{ details.listingName }}
      </div>
      <div class="row">
        <div class="col-7 pe-1">
          <div class="ribbon">
            <label class="text-white montserratSB font-14 px-2 py-1">{{details.stayCategories ?
              details.stayCategories[0]: ''}}</label>
          </div>
        </div>
        <div class="col-5 ps-0">
          <div class="font-16 text-end" id="ribbon">
            <div class="font-10 textLabel montserratL">
              {{ "homeDetailCard.fabulous" | translate }}
            </div>
            <rating [(ngModel)]="rate" [max]="max" [readonly]="isReadonly">
            </rating>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-8 pe-1 montserratR">
          <span class="font-14">{{ "homeDetailCard.from" | translate }}</span>
          <div class="font-24">
            &#8377;{{ details.basePriceWeekdays}}<span class="font-12">
              /{{priceFor}}</span>
          </div>
        </div>
        <div class="col-4 ps-0 text-end">
          <img alt="user" src="../../assets/img/user.jpg" class="rounded-circle userImg" />
        </div>
      </div>
    </slide>
  </carousel>
</div>