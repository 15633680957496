import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';

import { IProperties } from 'src/app/interfaces/listing-types.interface';
import { HomePageService } from 'src/app/services/home-page.service';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss']
})
export class TimePickerComponent implements OnInit {
  @Output() timeDataEvent = new EventEmitter<any>();
  @Input() timeInput?: Array<moment.Moment>;
  @Input() showLabel = true;
  @Input() conf: { localeString: string, showItems: number, showSlides: boolean } = {
    localeString: 'en',
    showItems: 2,
    showSlides: false
  };
  timePickerForm!: FormGroup;
  hourStep = 1;
  minStep = 30;
  selectedProperties: IProperties = {
    checkInTime: true,
    checkOutTime: false,
    children: false,
    dateRange: false,
    female: false,
    infant: false,
    male: false,
    room: false,
    packOfHours: false
  };
  packOfHours = [
    { id: 3, value: '3 hours' },
    { id: 6, value: '6 hours' },
    { id: 12, value: '12 hours' },
    { id: 24, value: '24 hours' }
  ];
  constructor(private homePageService: HomePageService) {
    this.propertiesSubscription();
  }

  propertiesSubscription() {
    this.homePageService.propertiesSubjectSubscription().subscribe((res: IProperties) => {
      this.selectedProperties = res;
    });
  }

  ngOnInit(): void {
    this.conf.showItems === 2 ? this.selectedProperties = this.homePageService.selectedProperties : '';
    this.initTimePickerForm();
  }

  initTimePickerForm() {
    const time = new Date();
    time.setHours(9, 0, 0);

    this.timePickerForm = new FormGroup({
      packOfHours: new FormControl({
        value: '',
        disabled: this.selectedProperties && this.selectedProperties.packOfHours ? false : true
      }),
      checkInTime: new FormControl({
        value: time,
        disabled: this.selectedProperties && this.selectedProperties.checkInTime ? false : true
      }),
      checkOutTime: new FormControl({
        value: time,
        disabled: this.selectedProperties && this.selectedProperties.checkOutTime ? false : true
      }),
    });

    this.timePickerForm.valueChanges.subscribe((value) => {
      // const checkInTime = moment(this.startDateEndDateForm.value.checkInTime).format('HH:mm');
      // const checkInDateTime = moment(`${startDate} ${checkInTime}`, 'YYYY-MM-DD HH:mm:ss').format();
      // const checkOutDateTime = moment(checkInDateTime).add(+this.startDateEndDateForm.value.packOfHours, 'hours').format('DD/MM/YYYY, h:mm a');
      this.timeDataEvent.emit(value);
    });

    if (this.timeInput && this.timeInput.length > 0) { this.setTime(); }
  }
  setTime() {
    this.timeInput && this.timeInput.length > 0 ? this.timePickerForm.get('checkInTime')?.setValue(this.timeInput[0].toDate()) : '';
    this.timeInput && this.timeInput.length > 1 ? this.timePickerForm.get('checkOutTime')?.setValue(this.timeInput[1].toDate()) : '';
  }

}
