import { NgModule } from '@angular/core';
import { IndianCurrency } from './currency.pipe';
import { CustomCapitalizePipe } from './customCapitalize.pipe';
import { DateAndTimePipe } from './date-and-time.pipe';
import { TimePipe } from './time.pipe';
import { TimeFormatter } from './timeconverter.pipe';


@NgModule({
    declarations: [IndianCurrency, DateAndTimePipe, TimePipe, CustomCapitalizePipe],
    exports: [IndianCurrency, DateAndTimePipe, TimePipe, CustomCapitalizePipe]
})
export class PipesModule { }