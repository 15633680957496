import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { Environment } from 'src/environments/environment';
import { RestService } from '../core/rest.service';
import { INotification, INotificationCount  } from '../interfaces/notification.interface';
import { IPaginationResponse } from '../interfaces/rest-page-response.interface';
import { IRestResponse } from '../interfaces/rest-response.interface';

@Injectable({
  providedIn: 'root'
})
export class NotificationService {

  constructor(public restService: RestService) { }
  getNewNotification(pageNumber:number): Observable<IRestResponse<IPaginationResponse<INotification[]>>> {
    return this.restService.get(Environment.UrlPath + '/notifications', { pageNumber, status: 'NEW' }, false, false).pipe(
      map((res: any) => res),
      catchError(error => throwError(error))
    );
  }
  getNewNotificationCount(): Observable<IRestResponse<INotificationCount>> {
    return this.restService.get(Environment.UrlPath + '/notifications/count?status=NEW', null, false, false).pipe(
      map((res: any) => res),
      catchError(error => throwError(error))
    );
  }
  updateNotificationsAsRead(data:any) {
    return this.restService.put(Environment.UrlPath + '/notifications', data, false).pipe(map((res: any) => res));
  }
}
