<ng-template #modal>
    <div class="p-5">
    <div class="orderSummary">
        <header class="modal-title text-white font-16 montserratSB px-4 py-3 text-center">Account</header>
        <!-- <button type="button" class="close pull-right" aria-label="Close" id="1" (click)="modalRef.hide()">
            <span aria-hidden="true">&times;</span>
        </button> -->
  
    <div class="text-center">
        <form [formGroup]="verifyForm">
            <p class="font-14  montserratSB pt-3">  To place your booking now, <br>
                log into your existing account or sign up. </p>
                <!-- <p class="pt-3 mb-0 montserratSB font-12">Sign up or <span (click)="onClickLogin()" class="pointer font-12 red-text">log in to your account</span> </p>
                 -->
                <p class="pt-3 mb-0 montserratSB font-12">Sign up or<span class="pointer font-12 red-text">  log in to your account </span></p>

            <!-- <input type="text" [formControl]="mobileNumberControl" maxlength="10"> -->
            <!-- <div class="error " *ngIf="mobileNumberControl.invalid && mobileNumberControl.dirty">
            <span *ngIf="mobileNumberControl.hasError('required')">
                {{ 'commonForm.mobileNumber.required' | translate }}
            </span>
            <span *ngIf="!mobileNumberControl.hasError('pattern') && 
            !mobileNumberControl.hasError('required') && 
            mobileNumberControl.hasError('minlength')">
                {{ 'commonForm.mobileNumber.minLength' | translate }}
            </span>
            <span *ngIf="!mobileNumberControl.hasError('required') && 
             mobileNumberControl.hasError('pattern')">
                {{ 'commonForm.mobileNumber.pattern' | translate }}
            </span>
        </div> -->

            <div class="contentBelowSubHeading p-3">
                <div class="row-12">
                    
                    <div class=" mb-3 px-2">
                        <input [readonly]="showVerification" pInputText formControlName="mobileNumber" type="text" placeholder="Phone Number"
                            class="w-100 light montserratR px-3 py-4 inputBoxAddUser font-14">
                        <div class="error red-text" *ngIf="verifyForm.get('mobileNumber')?.invalid && 
                        verifyForm.get('mobileNumber')?.dirty">
                            <span *ngIf="verifyForm.get('mobileNumber')?.hasError('required')">
                                Mobile Number is required!
                            </span>
                            <span *ngIf="!verifyForm.get('mobileNumber')?.hasError('pattern') &&
                                 verifyForm.get('mobileNumber')?.hasError('minlength')">
                                Mobile Number should have minimum 3 numbers !
                            </span>
                            <span *ngIf="!verifyForm.get('mobileNumber')?.hasError('pattern') && 
                                 verifyForm.get('mobileNumber')?.hasError('maxlength')">
                                Mobile Number should have maximum 18 numbers !
                            </span>
                            <span *ngIf="verifyForm.get('mobileNumber')?.hasError('pattern')">
                                Mobile Number is invalid!
                            </span>
                        </div>
                    </div>
                </div>
                <div *ngIf="showVerification" class="row-12">
                    
                    <div class=" mb-3 px-2">
                        <input pInputText formControlName="verificationCode" type="password"
                            placeholder="One Time Password"
                            class="w-100 light montserratR px-3 py-4 inputBoxAddUser font-14">
                        <div class="error red-text " *ngIf="verifyForm.controls['verificationCode'].invalid &&
                            verifyForm.controls['verificationCode'].dirty">
                            <span *ngIf="verifyForm.controls['verificationCode'].errors?.['required']">
                                Please enter the verificationCode.<br></span>
                            <span *ngIf="!verifyForm.controls['verificationCode'].errors?.['required'] && 
                                verifyForm.controls['verificationCode'].errors?.['minlength']">
                                verificationCode needs to be atleast 5 characters long.<br></span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="text-center py-4 px-4">
                <button *ngIf="!showVerification" class="font-18 w-100 text-white menuRedBG montserratSB py-3 border-0 submitBtn  pointer" id="generateOtp"
                    (click)="onClickGenerateOtp()">
                    {{'commonForm.generateOtp' | translate }}
                </button>

                <button *ngIf="showVerification" class="font-18 w-100 text-white menuRedBG montserratSB py-3 border-0 submitBtn  pointer" id="verifyButton"
                    (click)="onClickVerify()">
                    {{'commonForm.verifyButton' | translate }}
                </button>

            </div>
        </form>
    </div>
    </div>
</div>
</ng-template>

<app-register-modal [onOpenModal]="openRegister"></app-register-modal>