import { NgModule } from '@angular/core';
import { DragDropDirective } from '../directives/dragdrop.directive';
import { TextUpperCaseDirective } from '../directives/textcapitalize.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { TranslateModule } from '@ngx-translate/core';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TimepickerModule } from 'ngx-bootstrap/timepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { AgmCoreModule } from '@agm/core';
import { MatSliderModule } from '@angular/material/slider';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { ModalModule } from 'ngx-bootstrap/modal';
import { RatingModule } from 'ngx-bootstrap/rating';
import { CarouselModule } from 'ngx-bootstrap/carousel';
import { TabsModule } from 'ngx-bootstrap/tabs';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { PipesModule } from '../pipes/pipe.module';

@NgModule({
  declarations: [TextUpperCaseDirective, DragDropDirective],
  imports: [
    ModalModule.forRoot(),
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    TranslateModule,
    ScrollToModule,
    RatingModule,
    CarouselModule,
    TabsModule,
    BsDropdownModule,
    TimepickerModule,
    PipesModule
  ],
  exports: [
    DragDropDirective,
    TextUpperCaseDirective,
    ModalModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatSelectModule,
    TranslateModule,
    ScrollToModule,
    RatingModule,
    CarouselModule,
    TabsModule,
    BsDropdownModule,
    TimepickerModule,
    MatFormFieldModule,
    PaginationModule,
    AgmCoreModule,
    BsDatepickerModule,
    MatSliderModule,
    MatAutocompleteModule,
    PipesModule
  ],
  providers: [],
})
export class SharedModule { }


