import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { Subject } from 'rxjs';
import { sha256 } from 'js-sha256';
import { CustomValidators } from '../../validators/custom.validator';
import { validateAllFormFields } from 'src/app/utils/validateform';
import { AuthService, DataService, UserService } from 'src/app/services';
import { IRestResponse } from 'src/app/interfaces/rest-response.interface';
import { ILogin } from 'src/app/interfaces/login.interface';

@Component({
  selector: 'app-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent implements OnInit, OnDestroy {

  @ViewChild('modal') modal: any;
  @Input() onOpenModal!: Subject<any>;
  modalRef!: BsModalRef;
  eventsSubscription: any;
  loginForm!: FormGroup;
  openRegister: Subject<void> = new Subject<void>();
  openForgotPassword: Subject<void> = new Subject<void>();
  constructor(
    private modalService: BsModalService,
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private dataService: DataService,
    private userService: UserService
  ) { }

  ngOnInit(): void {

    this.initLoginForm();

    this.eventsSubscription = this.onOpenModal.subscribe(res => {
      this.modalRef = this.modalService.show(this.modal);
    });
  }
  initLoginForm() {
    this.loginForm = this.formBuilder.group({
      username: new FormControl(null, [
        Validators.required,
        Validators.minLength(3),
        Validators.maxLength(50),
        CustomValidators.email]),
      password:new FormControl('',([Validators.required])),
    })
  }

  onClickLogin() {
    if (this.loginForm.valid) {
      const data = {
        username: this.loginForm.value.username,
        password: sha256(this.loginForm.value.password),
        grant_type: 'password'
      };

      this.authService.login(data).subscribe((res:any) => {
        if (res.status.code === 200) {
          this.dataService.saveLoginData(res.response);
          this.modalRef.hide();
          this.userService.userAuthorized = true;
        }
      });
    } else {
      validateAllFormFields(this.loginForm);
    }
  }

  onClickCreateUser() {
    this.modalRef.hide();
    this.openRegister.next();
  }
  onClickForgotPassword() {
    this.modalRef.hide();
    this.openForgotPassword.next();
  }
  ngOnDestroy() {
    this.eventsSubscription.unsubscribe();
  }

}
