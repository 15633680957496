import { RestService } from './../core/rest.service';
import { Injectable } from '@angular/core';
import { map } from 'rxjs/operators';
import { IRestResponse } from '../interfaces/rest-response.interface';
import { User } from '../interfaces/user.interface';
import { Environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  public userAuthorized = false;

  constructor(public restService: RestService) { }

  registerUser(data:any, token = '') {
    return this.restService.post(Environment.UrlPath + `/user/register`, data, false, false, false)
      .pipe(map((res: any) => res));
  }

  verifyRegistrationOtp(data:any) {
    return this.restService.post(Environment.UrlPath + `/user/activate`, data, false, false, false)
      .pipe(map((res: any) => res));
  }

  resendRegistrationOtp(userId:string) {
    return this.restService.get(Environment.UrlPath + `/user/otp/resend/${userId}`, false, false, false)
      .pipe(map((res: any) => res));
  }

  getUser() {
    return this.restService.get(Environment.UrlPath + '/user', null)
      .pipe(map((res: any) => res));
  }

  userSignUp(data:any) {
    return this.restService.post(Environment.UrlPath + `/user/guestSignup`, data)
      .pipe(map((res: any) => res));
  }

  userVerifyOtp(data:any) {
    return this.restService.put(Environment.UrlPath + `/user/guestSignup/code/verification`, data)
      .pipe(map((res: any) => res));
  }

  generateOtp(mobileNo: string) {
    return this.restService.get(Environment.UrlPath + `/user/guestSignup/${mobileNo}/verification`, null)
      .pipe(map((res: any) => res));
  }
}
