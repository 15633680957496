<footer class="mt-5">
    <div class="container-fluid">
        <div class="row">
            <div class="col-xl-4 col-lg-4 col-md-3 col-sm-3 col-12 position-relative">
                <img alt="footer" src="../../../assets/img/footer-mobile.png"
                    class="img-fluid w-100 position-absolute mobileImg" />
            </div>
            <div class="col-xl-5 col-lg-4 col-md-3 col-sm-3 col-12">
                <div>
                    <img alt="logo" src="../../../assets/img/logo.png" class="logoFooter mt-5 mb-3" />
                </div>
                <h2 class="montserratB text-white font-36 mt-2">Your all time travel app.</h2>
                <p class="montserratL text-white font-16">
                    We don't do rentals, we bring families & friends together. Our goal is simple - build the largest
                    community of travellers hosting travellers with the shared belief that <span
                        class="montserratSB">"The World is a book
                        and
                        those who do not travel read only a page."</span>
                </p>
                <button class="py-2 px-5 font-16 montserratSB red border-0 mb-5 mt-3 text-white">
                    Join us today, it's free
                </button>
                <label class="mt-5 pt-5">
                    <a class="font-12 text-white montserratL text-decoration-none">Copyright 2020 @ Bookmyloft. All Right Reserved</a>
                </label>
            </div>
            <div class="col-xl-3 col-lg-4 col-md-3 col-sm-3 col-12 text-end">
                <div class="socialIcons my-5">
                    <a><img alt="facebook" src="../../../assets/img/fb.png" class="ms-3" /></a>
                    <a><img alt="facebook" src="../../../assets/img/insta.png" class="ms-3" /></a>
                    <a><img alt="facebook" src="../../../assets/img/twitter.png" class="ms-3" /></a>
                    <a><img alt="facebook" src="../../../assets/img/linkedin.png" class="ms-3" /></a>
                </div>
                <div class="text-white text-end montserratL font-14 textDownload pb-3 pt-5">
                    You can download our app in
                </div>
                <div class="row pb-5">
                    <div class="col-xl-8 col-lg-7 col-md-7 col-sm-6 col-6 text-end playstore pe-1">
                        <div (click)="onNavigateToAppDownload('ios')">
                            <img alt="App store" src="../../assets/img/app-store.png" class="img-fluid w-100 my-1 pointer" />
                        </div>
                        <div  (click)="onNavigateToAppDownload('playstore')">
                            <img alt="Play store" src="../../assets/img/play-store.png" class="img-fluid w-100 my-1 pointer" />
                        </div>
                    </div>
                    <div class="col-xl-4 col-lg-5 col-md-5 col-sm-6 col-6 text-end ps-1">
                        <img alt="barcode" src="../../assets/img/BML-qr-code.png" class="img-fluid w-100 barcode" />
                    </div>
                </div>

                <div class="row">
                    <div class="col-6">
                        <label class="mt-5 pt-5">
                            <a class="font-12 text-white montserratL text-decoration-none">Terms of Use</a>
                        </label>
                    </div>
                    <div class="col-6">
                        <label class="mt-5 pt-5">
                            <a class="font-12 text-white montserratL text-decoration-none">Privacy Policy</a>
                        </label>
                    </div>
                </div>

            </div>
        </div>
    </div>
</footer>
