import { Component, EventEmitter, OnInit, Output, Input, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import * as moment from 'moment';
import { IAvailabilityPrice } from 'src/app/interfaces/availability-price.interface';
import { IProperties } from 'src/app/interfaces/listing-types.interface';
import { IRestResponse } from 'src/app/interfaces/rest-response.interface';
import { ExperienceService } from 'src/app/services/experience.service';
import { HomePageService } from 'src/app/services/home-page.service';
import { PropertyService } from 'src/app/services/property.service';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.scss']
})
export class DatePickerComponent implements OnInit, OnDestroy {

  @Output() selectedDatesEvent = new EventEmitter<any>();
  @Output() selectedTimeEvent = new EventEmitter<any>();
  @Input() properties!: IProperties;
  @Input() dateInput?: Array<moment.Moment>;
  /* CONFIG */
  @Input() conf: { localeString: string, showItems: number, showSlides: boolean } = {
    localeString: 'en',
    showItems: 2,
    showSlides: false
  };
  /* GLOBAL */
  localeString = 'en';
  navDate: any;
  weekDaysHeaderArr: Array<string> = [];
  gridArr: Array<any> = [];
  selectedDate: any;
  selectedDates: Array<any> = [];
  selectedSingleDates: Array<any> = [];
  selectedRangeDates: Array<any> = [];
  range: Array<any> = [];
  currentRangeIndex = 0;
  _petitionType: any;
  selectedProperties: IProperties;
  selectedCategory:any;
  todaysDate = moment(new Date())
  get petitionType() {
    return this._petitionType;
  }

  set petitionType(value: string) {
    this._petitionType = value;
  }

  constructor(
    private homePageService: HomePageService,
    private propertyService: PropertyService,
    private experienceService: ExperienceService,
    private route: ActivatedRoute,
    private router: Router,
  ) {
    this.selectedProperties = this.homePageService.selectedProperties;
    this.setProperties();
    this.categorySubscription();
    this.selectedCategory = this.homePageService.getCategoryAsString(this.homePageService.selectedCategory);
    this.propertiesSubscription();
  }

  ngOnInit(): void {
    this.range[this.currentRangeIndex] = new Array();
    moment.locale(this.conf.localeString);
    this.navDate = moment.utc();
    this.weekDaysHeaderArr = moment.weekdays(true);
    this.makeGrid();
    this.paramSubscription();
  }
  onCloseCalender(): void {
    this.homePageService.closeCalender();
  }
  paramSubscription(): void {
    this.route.queryParamMap.subscribe((params:any) => {
      if (params.get('checkIn') || params.get('checkOut')) {
        if (this.range[0].length === 0) {
          params.get('checkIn') ? this.range[0].push(moment(new Date(params.get('checkIn')))) : '';
          params.get('checkOut') && params.get('checkIn') !== params.get('checkOut') ?
            this.range[0].push(moment(new Date(params.get('checkOut')))) : '';
          this.updateRange();
        }
        if (this.range[0].length === 1) {
          params.get('checkIn') ? this.range[0][0] = moment(new Date(params.get('checkIn'))) : '';
          ((params.get('checkOut') && params.get('checkIn') !== params.get('checkOut')) && this.petitionType === 'range') ?
            this.range[0].push(moment(new Date(params.get('checkOut')))) : '';
          this.updateRange();
        }
        if (this.range[0].length === 2) {
          params.get('checkIn') ? this.range[0][0] = moment(new Date(params.get('checkIn'))) : '';
          params.get('checkOut') && params.get('checkIn') !== params.get('checkOut') ?
            this.range[0][1] = moment(new Date(params.get('checkOut'))) : '';
          this.updateRange();
        }
      }

    });
  }
  setRange() {
    this.range[this.currentRangeIndex] = this.dateInput;
    this.updateRange();
  }

  categorySubscription(): void {
    this.homePageService.categorySubjectSubscription().subscribe((value) => {
      this.selectedCategory = this.homePageService.getCategoryAsString(value);
      this.range[this.currentRangeIndex] = new Array();
      moment.locale(this.conf.localeString);
      this.navDate = moment.utc();
      this.weekDaysHeaderArr = moment.weekdays(true);
      this.makeGrid();
    });
  }

  propertiesSubscription() {
    this.homePageService.propertiesSubjectSubscription().subscribe((res: IProperties) => {
      this.selectedProperties = res;
      this.setProperties();
    });
  }
  setProperties() {
    this.petitionType = this.selectedProperties && this.selectedProperties.dateRange ? 'range' : 'single';
  }

  /* Date in header */
  selectToday() {
    this.navDate = moment.utc();
    this.makeGrid();
  }


  /* Clear all selected days */
  clearDays() {
    this.range = new Array();
    this.currentRangeIndex = 0;
    this.range[this.currentRangeIndex] = new Array();
    this.selectedDates = new Array();
    this.selectedSingleDates = new Array();
    this.selectedRangeDates = new Array();
    this.selectedDatesEvent.emit(this.selectedRangeDates);

    this.selectedDate = null;
    this.makeGrid();
  }

  /* Nav Between Months */
  changeNavMonth(num: number) {
    if (this.canChangeNavMonth(num)) {
      this.navDate.add(num, 'month');
      this.makeGrid();
      this.paramChange({ calenderPage: this.navDate });
    }

  }
  public paramChange(paramValue: any): void {
    const queryParams: Params = paramValue;
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams,
        queryParamsHandling: 'merge'
      });
  }

  /*
    Limit calendar dates to ->
    PREV: -1 month
    NEXT: 1 year
  */
  canChangeNavMonth(num: number) {
    const clonedDate = moment.utc(this.navDate);
    clonedDate.add(num, 'month');
    const minDate = moment.utc().add(-1, 'month');
    const maxDate = moment.utc().add(1, 'year');

    return clonedDate.isBetween(minDate, maxDate);
  }

  showOnSingleCalendar() {
    return this.conf.showItems === 2;
  }
  createDateMatrix(arrayLength: number, initialEmptyCells: number, j: number, daysInMonth: any, firstDayDate: { clone: () => any; }, lastDayDate: { clone: () => any; }, monthDays: number, auxDate: any, priceArray?: IAvailabilityPrice[] | undefined): void {
    for (let i = 0; i < arrayLength; i++) {
      const obj: any = {};
      let day;

      if (i < initialEmptyCells || i > initialEmptyCells + daysInMonth - 1) {
        obj.available = false;
        if (i < initialEmptyCells) {
          day = firstDayDate.clone();
          day = day.subtract(initialEmptyCells - i, 'days');

        } else {
          const aux = i - (initialEmptyCells + daysInMonth - 1);
          day = lastDayDate.clone();
          day = day.add(aux, 'days');

        }

        obj.value = day.format('DD');
        obj.date = day;

      } else {
        obj.value = i - initialEmptyCells + 1;
        day = firstDayDate.clone();
        obj.date = day.add(i - initialEmptyCells, 'days');
        if (priceArray) {
          obj.price = priceArray[monthDays].price ? priceArray[monthDays].price : 'N.A.';
          obj.available = this.todaysDate.isAfter(obj.date) || priceArray[monthDays].price === '' ? false : true;
        } else {
          obj.available = this.todaysDate.isAfter(obj.date) ? false : true;
        }
        monthDays++;
      }

      this.gridArr[j].date = auxDate;
      this.gridArr[j].days.push(obj);

      const a = j + 1;
      const b = i + 1;
      if (b === arrayLength && a === this.conf.showItems && this.dateInput) {
        this.setRange();
      }
    }
    if (this.range[0].length > 0) {
      this.updateRange();
    }

  }
  getPriceForProperty(arrayLength: number, initialEmptyCells: number, j: number, daysInMonth: any, firstDayDate: any, lastDayDate:any, monthDays: number, auxDate: any): void {
    if (this.selectedCategory === 'EXPERIENCE_PROVIDER') {
      this.experienceService.getPriceForProperty(firstDayDate.format('YYYY-MM-DD'), lastDayDate.format('YYYY-MM-DD'))
        .subscribe((res: IRestResponse<Array<IAvailabilityPrice>>) => {
          if (res.status.code === 200) {
            const priceArray = res.response;
            /* Calendar & weekends */
            this.createDateMatrix(arrayLength, initialEmptyCells, j, daysInMonth,
              firstDayDate, lastDayDate, monthDays, auxDate, priceArray);
          }
        });
    } else {

      this.propertyService.
        getPriceForProperty(firstDayDate.format('YYYY-MM-DD'), lastDayDate.format('YYYY-MM-DD'),
          this.selectedCategory).subscribe((res: IRestResponse<Array<IAvailabilityPrice>>) => {
            if (res.status.code === 200) {
              const priceArray = res.response;
              /* Calendar & weekends */
              this.createDateMatrix(arrayLength, initialEmptyCells, j, daysInMonth,
                firstDayDate, lastDayDate, monthDays, auxDate, priceArray);
            }
          });
    }

  }
  /*
    UI print calendar
  */
  makeGrid() {
    this.gridArr = [];
    for (let j = 0; j < this.conf.showItems; j++) {

      const auxDate = moment.utc(this.navDate);
      auxDate.add(j, 'months');

      const firstDayDate = moment.utc(auxDate).startOf('month');
      const initialEmptyCells = firstDayDate.weekday();
      const lastDayDate = moment.utc(auxDate).endOf('month');
      const lastEmptyCells = 6 - lastDayDate.weekday();
      const daysInMonth = auxDate.daysInMonth();
      const arrayLength = initialEmptyCells + lastEmptyCells + daysInMonth;
      const monthDays = 0;


      this.gridArr[j] = [];
      this.gridArr[j].days = [];
      this.conf.showItems === 2 ?
        this.getPriceForProperty(arrayLength, initialEmptyCells, j, daysInMonth, firstDayDate, lastDayDate, monthDays, auxDate)
        : this.createDateMatrix(arrayLength, initialEmptyCells, j, daysInMonth, firstDayDate, lastDayDate, monthDays, auxDate);
    }
  }


  isAvailable(dateToCheck:string): boolean {
    // Weekend
    // if(dateToCheck.weekday() > 5){
    //   return false;
    // }
    return true;
  }

  /* OPs on presentation arrays*/
  setSelectedDates(date:string, data:any, isSingle = false) {
    if (data.includes(date) === false && isSingle === false) {
      data.push(date);
    }
    else {
      this.selectedSingleDates = new Array();
      data.push(date);
    }
  }

  unsetSelectedDates(date:any, data:any) {
    for (let i = 0; i < data.length; i++) {
      if (date.isSame(data[i], 'day')) {
        data.splice(i, 1);
      }
    }
  }

  /* Every click on days */
  selectDay(day: any) {

    if (day.available) {
      this.selectedDate = day.date;

      if (this.petitionType === 'range') {
        this.isRange(day);
        this.updateRange();
      } else {
        this.clearPerviousSingleDays();
        day.isSingle = !day.isSingle;
        if (day.isSingle) {
          this.range[this.currentRangeIndex] = new Array();
          this.range[this.currentRangeIndex].push(day.date);
          this.selectedDatesEvent.emit(this.range[0]);
        } else {
          this.range[this.currentRangeIndex] = new Array();
          this.selectedDatesEvent.emit(this.range[0]);
        }
      }

    }
  }
  clearPerviousSingleDays() {
    this.gridArr.forEach(month => {
      month.days.forEach((day: { isSingle: boolean; }) => {
        if (day.isSingle) {
          day.isSingle = false;
        }
      }
      );
    });
  }


  /* RANGES */
  updateRange() {
    this.selectedRangeDates = new Array();

    for (let i = 0; i < this.gridArr.length; i++) {

      for (let j = 0; j < this.gridArr[i].days.length; j++) {

        const obj = this.gridArr[i].days[j];
        obj.isRangeStart = false;
        obj.isRangeEnd = false;
        obj.isRange = false;
        obj.rangeIndex = -1;

        for (let k = 0; k < this.range.length; k++) {
          // Start Period
          if (this.range[k][0] && this.range[k][0].isSame(obj.date, 'day')) {
            obj.isRangeStart = true;
            this.setSelectedDates(obj.date, this.selectedRangeDates);
            obj.rangeIndex = k;
          }

          // End Period
          if (this.range[k][1] && this.range[k][1].isSame(obj.date, 'day')) {
            obj.isRangeEnd = true;
            this.setSelectedDates(obj.date, this.selectedRangeDates);
            obj.rangeIndex = k;
          }

          // Dates between end-points
          if (this.range[k][0] && this.range[k][1]) {
            if (obj.date.isBetween(this.range[k][0], this.range[k][1], 'days', '()')) {
              obj.isRange = true;
              obj.rangeIndex = k;

              if (obj.available) {
                this.setSelectedDates(obj.date, this.selectedRangeDates);
              }

              if (obj.isSingle) {
                obj.isSingle = false;
                this.unsetSelectedDates(obj.date, this.selectedSingleDates);
              }
            }
          }
        }

      }
    }
    this.selectedDatesEvent.emit(this.range[0]);
  }

  /*
    Range has 2 end-points
  */
  isRange(obj:any) {
    obj.isRangeStart = false;
    obj.isRangeEnd = false;
    obj.isRange = false;

    if (this.range[this.currentRangeIndex].length > 1) {
      const convertedFirstRangeDate = new Date(this.range[0][0]).toDateString();
      const convertedSecondRangeDate = new Date(this.range[0][1]).toDateString();
      const convertedSelectedDate = new Date(obj.date).toDateString();
      convertedSelectedDate < convertedFirstRangeDate ? this.range[0][0] = moment(convertedSelectedDate) : '';
      convertedSelectedDate > convertedFirstRangeDate ? this.range[0][1] = moment(convertedSelectedDate) : '';
      if (convertedSelectedDate === convertedFirstRangeDate || convertedSelectedDate === convertedSecondRangeDate) {
        this.range[this.currentRangeIndex] = new Array();
        this.range[this.currentRangeIndex].push(obj.date);
      };
    } else {
      this.range[this.currentRangeIndex].push(obj.date);

      if (this.range[this.currentRangeIndex].length === 2) {
        if (this.range[this.currentRangeIndex][0].isAfter(obj.date)) {
          this.range[this.currentRangeIndex].reverse();
        }
        this.checkRanges();
      }
    }
  }

  /*
    mix ranges on overlaps
  */
  checkRanges() {

    const startDate = this.range[this.currentRangeIndex][0];
    const endDate = this.range[this.currentRangeIndex][1];
    let delIndex = -1;

    for (let i = 0; i < this.currentRangeIndex; i++) {
      const startDateOld = this.range[i][0];
      const endDateOld = this.range[i][1] ? this.range[i][1] : undefined;

      // Overlap new range selection in beginning
      if (startDateOld.isBetween(startDate, endDate, 'days', '[]')) {
        if (endDateOld) {
          delIndex = i;
          if (!endDateOld.isBetween(startDate, endDate, 'days', '[]')) {
            this.range[this.currentRangeIndex][1] = endDateOld;
          }
        } else {
          delIndex = i;
        }
      }

      // Overlap new range selection in ending
      if (startDate.isBetween(startDateOld, endDateOld, 'days', '[]')) {

        if (endDate.isSameOrAfter(endDateOld, 'days')) {
          delIndex = i;
          this.range[this.currentRangeIndex][0] = startDateOld;
        }

      }
    }

    if (delIndex !== -1) {
      this.deleteRange(delIndex);
    }

  }

  /* Check if a range item has values */
  getCurrentRangeLength() {
    return this.range[this.currentRangeIndex].length;
  }

  /* Prepare next range item */
  addNewRange() {
    const newRange = new Array();
    this.range.push(newRange);
    this.currentRangeIndex++;
  }

  /* Delete Range item */
  deleteRange(delIndex:any) {
    this.range.splice(delIndex, 1);
    this.currentRangeIndex--;
  }

  /* Change petition types : single days OR period days */
  petitionTypeToggle() {
    if (this.petitionType === 'single') {
      this.petitionType = 'range';
    }
    else {
      this.petitionType = 'single';

      /* Consolidate period on days selected*/
      if (this.getCurrentRangeLength()) {
        this.addNewRange();
      }
    }
  }

  /* Check if a petition is range */
  isChecked() {
    return this.petitionType === 'range';
  }
  convertPriceToString(price: number): string {
    return price ? price.toString() : ''
  }
  onGetTimeData(timeData:any) {
    this.selectedTimeEvent.emit(timeData);
  }

  @HostListener('unloaded')
  ngOnDestroy() {
    console.log('Date-Picker Component Destroyed');
  }
}
